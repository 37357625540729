import { ResidentLogType, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const LogVisibilityModalActions = createActionGroup({
  source: 'Log Visibility Modal',
  events: {
    'Turn Off Log': (id: string, query?: any) => {
      return {
        id,
        params: { query }
      };
    },
    'Turn On Log': (residentLogType: ResidentLogType) => ({ residentLogType }),
    'Update Log Settings': props<Update<ResidentLogType>>()
  }
});
