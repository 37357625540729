import { RouterSelectors } from '$/app/store/router';
import { ResidentLogType } from '$/models';
import { Dictionary } from '$shared/types';
import { createSelector } from '@ngrx/store';
import { groupBy, isEmpty } from 'lodash';
import { residentLogTypesFeature } from './resident-log-types.reducer';
import { residentLogTypesAdapter } from './resident-log-types.state';

export const {
  selectResidentLogTypesState,
  selectIds,
  selectEntities,
  selectLoading,
  selectLoaded,
  selectError
} = residentLogTypesFeature;

export const { selectAll, selectTotal } = residentLogTypesAdapter.getSelectors(
  selectResidentLogTypesState
);

export const selectResidentLogTypes = createSelector(
  selectAll,
  RouterSelectors.selectParam('residentId'),
  (residentLogTypes, residentId) => {
    if (isEmpty(residentLogTypes) || !residentId) {
      return [];
    }

    return residentLogTypes.filter((residentLogType) => {
      return residentLogType.residentId === residentId;
    });
  }
);

export const selectResidentLogTypesByResident = createSelector(
  selectAll,
  (residentLogTypes): Dictionary<ResidentLogType[]> => {
    return groupBy(residentLogTypes, 'residentId');
  }
);

export const selectVisibleLogsByResident = createSelector(
  selectAll,
  (residentLogTypes): Dictionary<{ [key: string]: true }> => {
    const visibleLogs = residentLogTypes.reduce(
      (dictionary: Dictionary<{ [key: string]: true }>, residentLogType) => {
        dictionary[residentLogType.residentId] =
          dictionary[residentLogType.residentId] || {};

        dictionary[residentLogType.residentId][residentLogType.logTypeId] =
          true;

        return dictionary;
      },
      {}
    );

    return visibleLogs;
  }
);

export const selectVitalLogTypeByResident = createSelector(
  selectResidentLogTypes,
  (residentLogTypes) => {
    const test = residentLogTypes.find(
      (residentLogType) => residentLogType.logTypeId === 'vital'
    );
    return test;
  }
);
