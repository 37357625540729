import { Params, Update } from '$/models';
import { IMedicationOrder } from '$shared/medications/medication-order.schema';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationOrderDetailsPageActions = createActionGroup({
  source: 'Medication Order Details Page',
  events: {
    'Fetch Medication Order': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),
    'Delete Medication Order': props<{ id: string; params?: Params }>(),
    'Cancel Medication Order': props<Update<IMedicationOrder>>(),
    'Resend Medication Order': props<Update<IMedicationOrder>>()
  }
});
