export function getDocumentName<
  T extends { code?: string | null; name?: string | null }
>(document: T, defaultName: string = 'Untitled Document'): string {
  if (!document) {
    return 'No document';
  }

  return [
    document?.code ? `${document.code}:` : null,

    document?.name ? document.name : defaultName
  ]
    .filter(Boolean)
    .join(' ');
}
