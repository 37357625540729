import { IVitalLog } from '$shared/services/vital-log.schema';
import { Paginated } from '$shared/types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const VitalLogsApiActions = createActionGroup({
  source: 'Vital Logs Api',
  events: {
    'Load Vital Logs Success': props<{
      vitalLogs: Paginated<IVitalLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Get Vital Logs Success': props<{
      vitalLogs: Paginated<IVitalLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Create Vital Log Success': props<{
      vitalLog: IVitalLog;
    }>(),
    'Update Vital Log Success': props<{
      vitalLog: IVitalLog;
    }>(),
    'Delete Vital Log Success': props<{ id: string }>(),

    'Load Vital Logs Fail': props<{ error: Error }>(),
    'Get Vital Logs Fail': props<{ error: Error }>(),
    'Create Vital Log Fail': props<{ error: Error }>(),
    'Update Vital Log Fail': props<{ error: Error }>(),
    'Delete Vital Log Fail': props<{ error: Error }>()
  }
});

export const VitalLogsWsActions = createActionGroup({
  source: 'Vital Logs Ws',
  events: {
    'Vital Logs Created': props<{
      vitalLog: IVitalLog;
    }>(),
    'Vital Logs Patched': props<{
      vitalLog: IVitalLog;
    }>(),
    'Vital Logs Removed': props<{ id: string }>()
  }
});

export const VitalLogsGeneralActions = createActionGroup({
  source: 'Vital Logs General',
  events: {
    'Clear Vital Logs': emptyProps(),
    'Add Vital Logs': props<{
      vitalLogs: IVitalLog[];
    }>()
  }
});
