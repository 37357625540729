import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentGroupsListPageActions = createActionGroup({
  source: 'Resident Groups List Page',
  events: {
    'Load Resident Groups': (params: Params = { query: {} }) => ({
      params
    }),
    'Delete Resident Group': props<{ id: string; params?: Params }>()
  }
});
