import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { vitalLogsFeature } from './vital-logs.reducer';
import { VitalLogEffects } from './vital-logs.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(vitalLogsFeature),
    EffectsModule.forFeature([VitalLogEffects])
  ],
  providers: [VitalLogEffects]
})
export class VitalLogsStoreModule {}
