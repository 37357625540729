<ion-header>
  <ion-toolbar [color]="'primary' | alcTheme">
    <ion-title>
      {{
        medicalProfessional
          ? 'Edit Medical Professional'
          : 'New Medical Professional'
      }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close" slot="icon-only" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="form-content {{ theme }}-theme">
  <form [formGroup]="form" class="grid grid-cols-2 gap-6 p-6" novalidate>
    @if (proposedChange && proposedChange.action === 'update') {
      <alc-callout class="col-span-full" type="info">
        <p class="m-0">
          Below is is a list of proposed changes for this medical professional
          based on your profile of them and the information provided from the
          pharmacy. N/A means that the field is not currently set.
        </p>
        <p class="m-0 mt-2">
          You can reject these changes completely or make any necessary
          adjustments in the form below before submitting.
        </p>
      </alc-callout>

      <h2 class="col-span-full">Proposed Changes</h2>
      <alc-diff
        class="col-span-full mb-6"
        [original]="medicalProfessional"
        [new]="proposedChange.changes"
        [fields]="[
          { id: 'firstName', label: 'First Name' },
          { id: 'lastName', label: 'Last Name' },
          {
            id: 'npi',
            label: 'NPI',
            tooltip:
              'National Provider Identifier (NPI) is a unique 10-digit identification number issued to health care providers in the United States.'
          },
          { id: 'typeId', label: 'Type' },
          { id: 'specialty', label: 'Specialty' },
          { id: 'email', label: 'Email' },
          { id: 'address1', label: 'Address 1' },
          { id: 'address2', label: 'Address 2' },
          { id: 'city', label: 'City' },
          { id: 'state', label: 'State' },
          { id: 'postalCode', label: 'Postal Code' },
          { id: 'country', label: 'Country' }
        ]"
      />
      <div class="col-span-full mb-6 flex items-center justify-center">
        <ion-button shape="round" color="danger" (click)="rejectChanges()">
          Reject Proposed Changes
        </ion-button>
      </div>
    }

    <alc-form-divider class="col-span-2"> Basic Information </alc-form-divider>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>First Name</mat-label>
      <input matInput formControlName="firstName" />
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>Last Name</mat-label>
      <input acl-ctrl-input-last-name matInput formControlName="lastName" />
      <mat-error> required </mat-error>
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>NPI</mat-label>
      <input matInput formControlName="npi" />
      <mat-hint>National Provider Identifier</mat-hint>
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>Type</mat-label>
      <mat-select formControlName="typeId">
        <mat-option
          *ngFor="let professionalType of medicalProfessionalTypes()"
          [value]="professionalType?.id"
        >
          {{ professionalType?.name }}
        </mat-option>
      </mat-select>
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" />
      <mat-error>
        <span *ngIf="form.get('email')?.errors?.email">
          {{ form.get('email').errors.email.message }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>Specialty</mat-label>
      <input matInput formControlName="specialty" />
    </mat-form-field>

    <alc-form-divider class="col-span-2"> Phone Information </alc-form-divider>

    <div class="col-span-2 flex flex-col gap-6" formArrayName="phones">
      <div
        class="rounded-2xl border border-medium-light"
        *ngFor="let phone of formPhones.controls; let p = index; trackByIndex"
        [formGroupName]="p"
        @expansion
      >
        <div
          class="flex h-12 items-center justify-between border-b border-b-medium-light pl-4"
        >
          <p>Phone {{ p + 1 }}</p>

          <ion-button
            *ngIf="formPhones.controls.length"
            fill="clear"
            (click)="removePhone(p)"
          >
            <ion-icon
              slot="icon-only"
              src="/assets/icon/material/delete.svg"
              color="dark"
            />
          </ion-button>
        </div>
        <div class="grid grid-cols-2 gap-6 p-6">
          <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
            <mat-label>Label </mat-label>
            <input matInput type="text" formControlName="label" />
            <mat-error>Required</mat-error>
          </mat-form-field>

          <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
            <mat-label>Phone Number</mat-label>
            <input
              matInput
              type="tel"
              formControlName="number"
              mask="(000) 000-0000"
            />
            <mat-error ngxErrors="number">
              <span ngxError="required" when="touched">Required</span>
              <span ngxError="minlength" when="touched">
                Must be a 10 digit phone number.
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-span-2 sm:col-span-1">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type">
              <mat-option
                *ngFor="let phoneType of lookups.phoneTypes"
                [value]="phoneType?.id"
              >
                {{ phoneType?.name }}
              </mat-option>
            </mat-select>
            <mat-error> required </mat-error>
          </mat-form-field>

          <mat-checkbox
            class="col-span-2 sm:col-span-1"
            formControlName="isPrimary"
          >
            Primary Phone
          </mat-checkbox>
        </div>
      </div>
      <div class="col-span-2 flex justify-center">
        <ion-button
          fill="outline"
          shape="round"
          color="{{ theme }}-primary"
          (click)="addPhone($event)"
        >
          Add Phone
        </ion-button>
      </div>
    </div>

    <alc-form-divider class="col-span-2">
      Address Information
    </alc-form-divider>

    <mat-form-field class="form-input-stretch col-span-2">
      <mat-label>Address 1</mat-label>
      <input matInput formControlName="address1" />
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2">
      <mat-label>Address 2</mat-label>
      <input matInput formControlName="address2" />
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>City</mat-label>
      <input matInput formControlName="city" />
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>State</mat-label>
      <mat-select formControlName="state">
        <mat-option *ngFor="let state of lookups.states" [value]="state?.id">
          {{ state?.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>Postal Code</mat-label>
      <input matInput formControlName="postalCode" maxLength="5" />
    </mat-form-field>

    <mat-form-field class="form-input-stretch col-span-2 sm:col-span-1">
      <mat-label>Country</mat-label>
      <mat-select formControlName="country">
        <mat-option
          *ngFor="let country of lookups.countries"
          [value]="country?.id"
        >
          {{ country?.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <alc-form-divider class="col-span-2">
      Additional Information
    </alc-form-divider>

    <mat-form-field class="form-input-stretch col-span-2">
      <mat-label>Notes</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="4"
        formControlName="notes"
      ></textarea>
    </mat-form-field>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <div class="form-action-buttons">
      <ion-button
        fill="solid"
        shape="round"
        [color]="'primary' | alcTheme"
        (click)="submit()"
      >
        {{ medicalProfessional ? 'Update' : 'Create' }}
      </ion-button>
      <ion-button
        fill="outline"
        shape="round"
        [color]="'primary' | alcTheme"
        (click)="close()"
      >
        Cancel
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
