import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { respirationLogsFeature } from './respiration-logs.reducer';
import { RespirationLogsEffects } from './respiration-logs.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(respirationLogsFeature),
    EffectsModule.forFeature([RespirationLogsEffects])
  ],
  providers: [RespirationLogsEffects]
})
export class RespirationLogsStoreModule {}
