import { Params, Update } from '$/models';
import { IVitalLog } from '$shared/services/vital-log.schema';
import { createActionGroup, props } from '@ngrx/store';

type VitalLogUpdate = {
  bloodPressureLog: {
    changes: {
      systolic: number;
      diastolic: number;
      pulse: number;
      notes: string | null;
    };
    id: string;
  };
  bloodSugarLog: {
    changes: {
      level: number;
      notes: string;
    };
    id: string;
  };
  oxygenLog: {
    changes: {
      level: number;
      pulse: string;
      notes: string;
    };
    id: string;
  };
  respirationLog: {
    changes: {
      rate: number;
      rhythm: string;
      depth: string;
      notes: string;
    };
    id: string;
  };
  temperatureLog: {
    changes: {
      temperature: number;
      notes: string;
    };
    id: string;
  };
  notes: string;
  recordAt: string;
  recordedBy: string;
};

export const VitalLogFormPageActions = createActionGroup({
  source: 'Vital Log Form Page',
  events: {
    'Create Vital Log': props<{
      vitalLog: IVitalLog;
      params?: Params;
    }>(),
    'Update Vital Log': props<Update<VitalLogUpdate>>()
  }
});
