import { MedicationReceivingTableActions } from '$/app/pages/medications/dashboard/medication-receiving/medication-receiving-table/medication-receiving-table.actions';
import { MedicationReceivingPageActions } from '$/app/pages/medications/dashboard/medication-receiving/medication-receiving.actions';
import { EffectHelpersService, OverlayService } from '$/app/services';
import { ApiData, getAllPages, scanPaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PharmacyApiMessagesApiActions } from './pharmacy-api-messages.actions';
import { PharmacyApiMessagesApiService } from './pharmacy-api-messages.service';

@Injectable()
export class PharmacyApiMessagesEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly overlay = inject(OverlayService);
  private readonly pharmacyApiMessagesService = inject(
    PharmacyApiMessagesApiService
  );

  loadPharmacyApiMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MedicationReceivingPageActions.loadPharmacyApiMessages),
      this.effectHelpers.apiRequest({
        description: 'Load pharmacy API messages',
        onRequest: (action) =>
          this.pharmacyApiMessagesService
            .getAll(action.params)
            .pipe(
              getAllPages(this.pharmacyApiMessagesService, action.params.query),
              scanPaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'pharmacyApiMessages',
            response,
            PharmacyApiMessagesApiActions.loadPharmacyApiMessagesSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          PharmacyApiMessagesApiActions.loadPharmacyApiMessagesFail({ error })
      })
    );
  });

  updatePharmacyApiMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MedicationReceivingTableActions.acceptPharmacyApiMessages,
        MedicationReceivingTableActions.rejectPharmacyApiMessages,
        MedicationReceivingTableActions.changePharmacyApiMessageRelation,
        MedicationReceivingPageActions.changePharmacyApiMessageRelation
      ),
      this.effectHelpers.apiRequest({
        description: 'Update pharmacy API message',
        onRequest: (action) =>
          this.pharmacyApiMessagesService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (pharmacyApiMessage) =>
          PharmacyApiMessagesApiActions.updatePharmacyApiMessageSuccess({
            pharmacyApiMessage
          }),
        onError: (error) =>
          PharmacyApiMessagesApiActions.updatePharmacyApiMessageFail({ error })
      })
    );
  });
}
