import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'alc-release',
  imports: [CommonModule],
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcReleaseComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() date: string;
  @Input() codename: string;
}
