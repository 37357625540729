import { IDocument, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const DocumentsPortalOverviewPageActions = createActionGroup({
  source: 'Documents Portal Overview Page',
  events: {
    'Load Documents': (params: Params) => ({ params }),
    'Upload Document File': props<Update<IDocument>>()
  }
});
