alc-callout {
  display: block;

  .default {
    @apply border-gray-400 bg-gray-50 text-gray-700;
  }

  .info {
    @apply border-sky-400 bg-sky-50 text-sky-700;
  }

  .warning {
    @apply border-yellow-400 bg-yellow-50 text-yellow-700;
  }

  .error {
    @apply border-red-400 bg-red-50 text-red-700;
  }

  .success {
    @apply border-green-400 bg-green-50 text-green-700;
  }
}
