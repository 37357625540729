<div class="flex flex-col items-start gap-1 py-2">
  <div
    class="flex shrink-0 items-center justify-center rounded-full px-2 py-1 text-white"
    [ngClass]="{
      'bg-primary': type() === 'improvement',
      'bg-warning': type() === 'change',
      'bg-success': type() === 'feature',
      'bg-danger': type() === 'bugfix'
    }"
    [matTooltip]="tooltip()"
  >
    <ion-icon class="mr-1 shrink-0 text-base" [src]="icon()" />
    <span class="text-xs font-bold tracking-wider">
      {{ (type() === 'improvement' ? 'improvement' : type()) | uppercase }}
    </span>
  </div>
  <p class="m-0 text-base text-dark">
    <ng-content />
  </p>
</div>
