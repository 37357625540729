import { IDocument, Params, Update } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const DocumentsPortalFormShellPageActions = createActionGroup({
  source: 'Documents Portal Form Shell Page',
  events: {
    'Fetch Document': (id: string, params: Params = { query: {} }) => ({
      id,
      params
    }),
    'Update Document Form': (
      id: string,
      changes: Partial<IDocument>,
      params: Params
    ): Update<IDocument> => ({
      id,
      changes,
      params
    }),
    'Upload Document File': props<Update<IDocument>>()
  }
});
