<div class="mb-2 mt-6 flex flex-col items-start gap-2">
  <div class="flex items-center gap-2">
    <div
      class="flex items-center rounded-full px-3 py-1 text-white"
      [ngClass]="{
        'bg-primary': type() === 'improvement',
        'bg-warning': type() === 'change',
        'bg-success': type() === 'feature',
        'bg-danger': type() === 'bugfix'
      }"
      [matTooltip]="tooltip()"
    >
      <ion-icon class="mr-2 text-lg" [src]="icon()" />
      <span class="text-sm font-bold tracking-wider">
        {{ type() | uppercase }}
      </span>
    </div>
    @if (premium()) {
      <div
        class="flex items-center rounded-full border border-amber-400 bg-gradient-to-br from-amber-300 to-amber-400 px-3 py-1 text-white"
        matTooltip="Premium features are only available to premium customers. If you have been with Alcomy for any significant amount of time, chances are you have been grandfathered into a premium account. If you do not have access to this and other premium features and would like learn how to upgrade, please reach out to sales or your customer success rep."
      >
        <ion-icon
          class="mr-2 text-lg"
          src="assets/icon/material/editor_choice.svg"
        />
        <span class="text-sm font-bold tracking-wider"> Premium </span>
      </div>
    }
  </div>

  <h4 class="m-0 font-bold">
    {{ title() }}
  </h4>

  <ng-content />
</div>
