import { Paginated } from '$/models';
import { IFile } from '$shared/services/file.schema';
import { createActionGroup, props } from '@ngrx/store';

export const FilesApiActions = createActionGroup({
  source: 'Files API',
  events: {
    'Load Files Success': props<{ files: Paginated<IFile> }>(),
    'Get Files Success': props<{ files: Paginated<IFile> }>(),
    'Fetch File Success': props<{ file: IFile }>(),
    'Create File Success': props<{ file: IFile }>(),
    'Update File Success': props<{ file: IFile }>(),
    'Delete File Success': props<{ id: string }>(),

    'Load Files Fail': props<{ error: Error }>(),
    'Get Files Fail': props<{ error: Error }>(),
    'Fetch File Fail': props<{ error: Error }>(),
    'Create File Fail': props<{ error: Error }>(),
    'Update File Fail': props<{ error: Error }>(),
    'Delete File Fail': props<{ error: Error }>()
  }
});

export const FilesWsActions = createActionGroup({
  source: 'Files WS',
  events: {
    'File Created': props<{ file: IFile }>(),
    'File Patched': props<{ file: IFile }>(),
    'File Removed': props<{ id: string }>()
  }
});
