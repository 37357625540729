import { Update } from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { Params } from '@angular/router';
import { createActionGroup, props } from '@ngrx/store';

export const ExpiredInventoryItemsPageActions = createActionGroup({
  source: 'Expired Inventory Items Page',
  events: {
    'Load Expired Inventory Items': (params: Params) => ({ params }),
    'Mark Inventory Empty': props<Update<IMedicationInventoryItem>>()
  }
});
