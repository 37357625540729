import { AlcomyRouterState } from '$/app/store/router/custom-route-serializer';
import { ActionWithProps } from '$/models';
import { Logger } from '$shared/logger';
import {
  ROUTER_NAVIGATED,
  ROUTER_NAVIGATION,
  ROUTER_REQUEST,
  RouterNavigationAction
} from '@ngrx/router-store';
import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { cloneDeep, pick } from 'lodash';
import { stringify } from 'safe-stable-stringify';
import { AppInfo, MixpanelService } from '../services';
import { AppActions } from './app.actions';
import { State } from './app.state';

const NON_USER_ACTION_KEYWORDS = [
  'Success',
  'Clear',
  'Add',
  'Created',
  'Patched',
  'Removed'
];

const NON_USER_ACTIONS = [ROUTER_REQUEST, ROUTER_NAVIGATION, ROUTER_NAVIGATED];

export const getMetaReducers = (
  mixpanel: MixpanelService
): MetaReducer<State>[] => {
  return [
    clearState,
    logApiErrors,
    trackUserActionsFactory(mixpanel),
    trackNavigationFactory(mixpanel)
  ];
};

export function clearState(reducer: ActionReducer<State>) {
  return (state: any, action: Action) => {
    switch (true) {
      case action.type === AppActions.clearStore.type:
        Logger.info('Clearing state', { action, appInfo: AppInfo });

        state = cloneDeep(pick(state, ['router', 'geolocation']));
        break;

      case action.type === AppActions.clearAllNonUserState.type:
        Logger.info('Clearing non user state', { action, appInfo: AppInfo });

        state = cloneDeep(
          pick(state, [
            'router',
            'user',
            'organizations',
            'facilities',
            'facilitySettings'
          ])
        );
        break;
    }

    return reducer(state as State, action);
  };
}

export function logApiErrors(reducer: ActionReducer<State>) {
  return (state: State, action: any) => {
    const error = action.error;

    if (error || action.type?.endsWith('Fail')) {
      if (!error?.isLogged) {
        switch (error?.className) {
          case 'location-geofence-violation-error':
            Logger.warn('Geofence violation', { action });
            break;

          case 'location-ip-violation-error':
            Logger.warn('IP address violation', { action });
            break;

          case 'timeout':
            Logger.warn(`API Timeout: ${action.type}`, { action });
            break;

          case 'app-out-of-date-error':
          case 'invalid-password-error':
          case 'email-not-found-error':
            // Don't log these errors
            break;

          default:
            Logger.error('Unknown Error', { action });
            break;
        }
      }
    }

    return reducer(state, action as Action);
  };
}

export function trackNavigationFactory(mixpanel: MixpanelService) {
  return (reducer: ActionReducer<State>) => {
    return (
      state: State,
      action: ActionWithProps | RouterNavigationAction<AlcomyRouterState>
    ) => {
      if (action.type === ROUTER_NAVIGATED) {
        // NOTE: Mixpanel recommends that page view events be categorized under
        // a single event name rather than a unique event name for each page.
        // Details about the page should be captured as custom properties.
        mixpanel.track('Page Viewed', {
          url: action.payload.routerState.url,
          title: action.payload.routerState.title,
          route: action.payload.routerState.routeConfigPath,
          params: action.payload.routerState.params,
          queryParams: action.payload.routerState.queryParams
        });
      }

      return reducer(state, action);
    };
  };
}

export function trackUserActionsFactory(mixpanel: MixpanelService) {
  return (reducer: ActionReducer<State>) => {
    return (state: State, action: ActionWithProps) => {
      const ignore =
        NON_USER_ACTION_KEYWORDS.some((keyword) =>
          action.type?.includes(keyword)
        ) ||
        NON_USER_ACTIONS.some(
          (actionToExclude) => action.type === actionToExclude
        );

      if (!ignore) {
        // NOTE: Mixpanel recommends that event names be generic and to use
        // custom properties to capture specific information. This allows the
        // core event to be indexed and reported on much easier. Therefore,
        // I've opted to extract the source information from the action type
        // and include it as a custom property. The action name (text that
        // comes after '[' and ']') will be used as the event name.

        // The regex captures all text between square brackets (if they exist)
        // and then anything outside the square brackets.
        const [, source, actionName] = action.type?.match(
          /^(?:\[(.*?)\]\s*)?(.*)$/
        ) ?? ['unknown', 'unknown'];

        mixpanel.track(actionName, {
          source,
          actionId: action.id,
          query: stringify(action.params?.query) ?? 'No query',
          error: action.error
            ? (action.error.message ?? 'No error message')
            : undefined,
          errorStack: action.error
            ? (action.error.stack ?? 'No error stack')
            : undefined
        });
      }

      return reducer(state, action);
    };
  };
}
