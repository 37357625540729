import { Update } from '$/models';
import { IRespirationLog } from '$shared/services/respiration-log.schema';
import { createActionGroup, props } from '@ngrx/store';

export const RespirationLogFormPageActions = createActionGroup({
  source: 'Respiration Log Form Page',
  events: {
    'Create Respiration Log': props<{
      respirationLog: IRespirationLog;
    }>(),
    'Update Respiration Log': props<Update<IRespirationLog>>()
  }
});
