import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const RespirationLogsPageActions = createActionGroup({
  source: 'Respiration Log Page',
  events: {
    'Load Respiration Logs': (params: Params) => ({ params }),
    'Get Respiration Logs': (params: Params) => ({ params }),
    'Delete Respiration Log': props<{ id: string; params?: Params }>()
  }
});
