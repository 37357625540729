import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { sortByDesc } from '$/app/utils/sorting/sort-by-desc';
import { IRespirationLog } from '$shared/services/respiration-log.schema';
import { IPaginationInfo } from '$/models';

export interface RespirationLogPageQueries {
  all: IPaginationInfo;
}

export const respirationLogsAdapter = createEntityAdapter<IRespirationLog>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<IRespirationLog> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = respirationLogsAdapter.getInitialState({
  pagination: {
    all: {
      total: 0,
      skip: 0
    }
  },
  loading: false,
  loaded: false,
  error: null
});
