import { Params, Update } from '$/models';
import { ResidentGroup } from '$/models/data/entities/residents/resident-group.model';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentGroupFormActions = createActionGroup({
  source: 'Resident Group Picker',
  events: {
    'Load Resident Group Members': (params: Params = { query: {} }) => ({
      params
    }),
    'Get Resident Group Members': props<{ params: Params }>(),

    'Create Resident Group': props<{
      residentGroup: ResidentGroup;
      params?: Params;
    }>(),
    'Update Resident Group': props<Update<ResidentGroup>>()
  }
});
