@if (isPdfLoading() && loadingType() === 'inline') {
  <div class="flex justify-center">
    <alc-loading-placeholder text="Loading PDF..." />
  </div>
}

<!-- <div>{{ url }}</div> -->
<ngx-extended-pdf-viewer
  [src]="url"
  [logLevel]="0"
  [showToolbar]="false"
  [showSidebarButton]="false"
  [showSecondaryToolbarButton]="false"
  [showOpenFileButton]="false"
  [showRotateButton]="false"
  [showPagingButtons]="false"
  [showZoomButtons]="true"
  [showFindButton]="false"
  [showDownloadButton]="showDownload"
  [textLayer]="false"
  [sidebarVisible]="false"
  backgroundColor="#00000000"
  (pdfLoaded)="onPagesLoaded()"
  (afterPrint)="onAfterPrint($event)"
/>
