import { Params, Update } from '$/models';
import { IMedicationInventoryItem } from '$shared/medication-inventory-items';
import { MedicationTaskSubmission } from '$shared/medication-tasks';
import { createActionGroup, props } from '@ngrx/store';

const singleTaskSubmission = (
  id: string,
  changes: MedicationTaskSubmission,
  params: Params = { query: {} }
) => ({ id, changes, params });

const multiTaskSubmission = (
  changes: MedicationTaskSubmission[],
  params: Params = { query: {} }
) => ({ changes, params });

export const ResidentMedicationTasksPageActions = createActionGroup({
  source: 'Resident Medication Tasks Page',
  events: {
    'Get Medication Tasks': (params: Params = { query: {} }) => ({ params }),
    'Prep Medication': singleTaskSubmission,
    'Give Medication': singleTaskSubmission,
    'Omit Medication': singleTaskSubmission,
    'Undo Prep Medication': singleTaskSubmission,
    'Undo Give Medication': singleTaskSubmission,
    'Undo Omit Medication': singleTaskSubmission,

    'Prep All Medications': multiTaskSubmission,
    'Give All Medications': multiTaskSubmission,
    'Omit All Medications': multiTaskSubmission,
    'Undo Prep All Medications': multiTaskSubmission,
    'Undo Give All Medications': multiTaskSubmission,
    'Undo Omit All Medications': multiTaskSubmission,

    'Flag For Ordering': (id: string, params: Params = { query: {} }) => {
      return {
        id,
        medication: { needsOrdering: true },
        params
      };
    },
    'Unflag For Ordering': (id: string, params: Params = { query: {} }) => {
      return {
        id,
        medication: { needsOrdering: false },
        params
      };
    },
    'Mark Inventory Empty': props<Update<IMedicationInventoryItem>>(),
    'Delete Activity Log': props<{ id: string; params?: Params }>(),
    'Delete Behavior Log': props<{ id: string; params?: Params }>(),
    'Delete Blood Pressure Log': props<{ id: string; params?: Params }>(),
    'Delete Blood Sugar Log': props<{ id: string; params?: Params }>(),
    'Delete Body Check Log': props<{ id: string; params?: Params }>(),
    'Delete Bowel Movement Log': props<{ id: string; params?: Params }>(),
    'Delete Food Log': props<{ id: string; params?: Params }>(),
    'Delete Mood Log': props<{ id: string; params?: Params }>(),
    'Delete Oxygen Log': props<{ id: string; params?: Params }>(),
    'Delete Position Log': props<{ id: string; params?: Params }>(),
    'Delete Respiration Log': props<{ id: string; params?: Params }>(),
    'Delete Seizure Log': props<{ id: string; params?: Params }>(),
    'Delete Shower Log': props<{ id: string; params?: Params }>(),
    'Delete Sleep Log': props<{ id: string; params?: Params }>(),
    'Delete Temperature Log': props<{ id: string; params?: Params }>(),
    'Delete Urine Log': props<{ id: string; params?: Params }>(),
    'Delete Vital Log': props<{ id: string; params?: Params }>(),
    'Delete Water Log': props<{ id: string; params?: Params }>(),
    'Delete Weight Log': props<{ id: string; params?: Params }>()
  }
});
