import { createFeatureSelector, createSelector } from '@ngrx/store';

import { groupBy } from 'lodash';
import { pharmacyPhonesAdapter, State } from './pharmacy-phones.state';

// Selector Helpers

export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getError = (state: State) => state.error;

// PharmacyPhones State Selector
export const selectPharmacyPhonesState =
  createFeatureSelector<State>('pharmacyPhones');

const entitySelectors = pharmacyPhonesAdapter.getSelectors(
  selectPharmacyPhonesState
);

// Entity Selectors
export const selectAll = entitySelectors.selectAll;
export const selectEntities = entitySelectors.selectEntities;
export const selectIds = entitySelectors.selectIds;
export const selectTotal = entitySelectors.selectTotal;

// Extras Selectors
export const selectLoading = createSelector(
  selectPharmacyPhonesState,
  getLoading
);
export const selectLoaded = createSelector(
  selectPharmacyPhonesState,
  getLoaded
);
export const selectError = createSelector(selectPharmacyPhonesState, getError);

export const selectAllGroupedByPharmacyId = createSelector(
  selectAll,
  (pharmacyPhones) => groupBy(pharmacyPhones, 'pharmacyId')
);
