import {
  NintendoVillageFacilityId,
  NintendoVillageOrgId
} from './constants/ids';
import {
  FeatureFlagId,
  getDefaultFeatureFlags
} from './lookups/feature-flags.lookup';
import { StateId } from './lookups/states.lookup';

export function applyDefaultsToFacilityFeatureFlags(
  facilityFeatureFlags: Record<string, boolean>,
  facility?: { id?: string; state: StateId; orgId: string }
): Record<FeatureFlagId, boolean> {
  if (!facility) {
    return getDefaultFeatureFlags();
  }

  const flags: Record<FeatureFlagId, boolean> = {
    documents: true,
    'documents-resident': true,
    'documents-staff': facility?.orgId === NintendoVillageOrgId,
    'documents-facility': facility?.orgId === NintendoVillageOrgId,
    'documents-incomplete': facility?.orgId === NintendoVillageOrgId,
    'documents-forms': facility?.id === NintendoVillageFacilityId,
    'incident-reports': facility?.state === 'US-CA',
    experiments: facility?.orgId === NintendoVillageOrgId,
    'move-resident': facility?.orgId === NintendoVillageOrgId,
    'medication-destruction': facility?.orgId === NintendoVillageOrgId,
    'narcotics-count': facility?.orgId === NintendoVillageOrgId,
    'group-by-care-task-list': facility?.id === NintendoVillageFacilityId,
    'pharmacy-integration-medication-review':
      facility?.id === NintendoVillageFacilityId,
    'resident-reports': facility?.orgId === NintendoVillageOrgId,
    'ai-summarize-notes': facility?.orgId === NintendoVillageOrgId
  };

  for (const [flag, enabled] of Object.entries(facilityFeatureFlags)) {
    if (flag in flags) {
      flags[flag as FeatureFlagId] ||= enabled;
    }
  }

  return flags;
}
