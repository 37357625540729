/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-interface */

// This file is generated by backend/src/lib/codegen/#process-service-actions.ts
// Do not edit this file directly

export type ServiceActionGroup = {
  [K in ServiceActionName]?: ServiceActions[K] | boolean | null;
};

export type ServiceActions = {
  acceptProposedInventoryItems: AcceptProposedInventoryItems;
  adminAddSetupUser: AdminAddSetupUser;
  adminChangeFacilityTimezone: AdminChangeFacilityTimezone;
  adminCreateFacility: AdminCreateFacility;
  adminCreateOrganization: AdminCreateOrganization;
  adminFindFacilities: AdminFindFacilities;
  adminFindFacilityUsers: AdminFindFacilityUsers;
  adminFindFeatureFlagFacilities: AdminFindFeatureFlagFacilities;
  adminFindFeatureFlags: AdminFindFeatureFlags;
  adminFindMissedMedicationTasks: AdminFindMissedMedicationTasks;
  adminFindOrganizations: AdminFindOrganizations;
  adminGenerateFacilityUserCSV: NoParams;
  adminRemoveSetupUser: AdminRemoveSetupUser;
  adminUpdateFacility: AdminUpdateFacility;
  adminUpdateFeatureFlags: AdminUpdateFeatureFlags;
  archive: NoParams;
  completeDocument: NoParams;
  completeSignup: CompleteSignup;
  createActivityResidents: CreateActivityResidents;
  createDocumentFromTemplate: CreateDocumentFromTemplate;
  createFacilityUsers: CreateFacilityUsers;
  createPhones: CreatePhones;
  deactivateResident: DeactivateResident;
  destroyMedicationDestructionItems: NoParams;
  duplicateFile: DuplicateFile;
  editMedication: EditMedication;
  findByEmail: FindByEmail;
  generatePdf: NoParams;
  getUrl: NoParams;
  getUserOnly: NoParams;
  handleLogChanges: HandleLogChanges;
  linkDocumentTemplatesToPacket: LinkDocumentTemplatesToPacket;
  linkDocumentsToPacket: LinkDocumentsToPacket;
  linkLog: LinkLog;
  manageParticipants: ManageParticipants;
  mergeMedicalProfessionals: MergeMedicalProfessionals;
  mergePharmacies: MergePharmacies;
  moveDocuments: MoveDocuments;
  moveResident: MoveResident;
  notifyParticipants: NotifyParticipants;
  passwordChange: PasswordChange;
  registerFcmToken: RegisterFcmToken;
  removeAcknowledgements: NoParams;
  removeDocumentPackets: NoParams;
  removeFile: RemoveFile;
  removeFromAllGroups: NoParams;
  renewDocument: RenewDocument;
  renewSigningToken: RenewSigningToken;
  requestNewInvitation: RequestNewInvitation;
  resendMedicationOrder: NoParams;
  resetPwdLong: ResetPwdLong;
  resolveFormData: NoParams;
  sendInviteEmail: NoParams;
  sendResetPwd: SendResetPwd;
  signDocumentForm: SignDocumentForm;
  signFile: SignFile;
  submitToLicensing: NoParams;
  submitToLicensingForDeathReport: NoParams;
  unlinkDocumentTemplatesFromPacket: UnlinkDocumentTemplatesFromPacket;
  unlinkDocumentsFromPacket: UnlinkDocumentsFromPacket;
  unregisterFcmToken: UnregisterFcmToken;
  updateMedication: UpdateMedication;
  updateProposedChange: UpdateProposedChange;
  updateRoles: UpdateRoles;
  uploadAttachedFile: UploadAttachedFile;
  uploadDocumentFile: UploadDocumentFile;
  uploadPrescriptionPicture: UploadPrescriptionPicture;
  uploadProfilePicture: UploadProfilePicture;
  validateResetToken: ValidateResetToken;
  validateSignupToken: ValidateSignupToken;
};

export type ServiceActionName = keyof ServiceActions;

interface AcceptProposedInventoryItems {
  acceptedItems: {
    proposedChangeId: string;
    medicationInventoryItem: unknown;
  }[];
}

interface AdminAddSetupUser {
  facilityId: string;
}

interface AdminChangeFacilityTimezone {
  facilityId: string;
  newTimezone: 'America/Los_Angeles' | 'America/Denver' | 'America/Phoenix' | 'America/Chicago' | 'America/New_York';
}

interface AdminCreateFacility {
  name: string;
  capacity: number;
  orgId: string;
  type: 'rcfe' | 'arf' | 'groupHome' | 'adp' | 'srf' | 'ffh' | 'sfh' | 'rcfci';
  state: 'US-AL' | 'US-AK' | 'US-AZ' | 'US-AR' | 'US-CA' | 'US-CO' | 'US-CT' | 'US-DE' | 'US-FL' | 'US-GA' | 'US-HI' | 'US-ID' | 'US-IL' | 'US-IN' | 'US-IA' | 'US-KS' | 'US-KY' | 'US-LA' | 'US-ME' | 'US-MD' | 'US-MA' | 'US-MI' | 'US-MN' | 'US-MS' | 'US-MO' | 'US-MT' | 'US-NE' | 'US-NV' | 'US-NH' | 'US-NJ' | 'US-NM' | 'US-NY' | 'US-NC' | 'US-ND' | 'US-OH' | 'US-OK' | 'US-OR' | 'US-PA' | 'US-RI' | 'US-SC' | 'US-SD' | 'US-TN' | 'US-TX' | 'US-UT' | 'US-VT' | 'US-VA' | 'US-WA' | 'US-WV' | 'US-WI' | 'US-WY' | 'US-DC';
  timezone: 'America/Los_Angeles' | 'America/Denver' | 'America/Phoenix' | 'America/Chicago' | 'America/New_York';
}

interface AdminCreateOrganization {
  name: string;
  displayName: string;
}

interface AdminFindFacilities {
  query?: {
    [k: string]: unknown;
  };
}

interface AdminFindFacilityUsers {
  facilityId: string;
}

interface AdminFindFeatureFlagFacilities {
  featureFlagId: string;
}

interface AdminFindFeatureFlags {
  facilityId: string;
}

interface AdminFindMissedMedicationTasks {
  query: {
    [k: string]: unknown;
  };
}

interface AdminFindOrganizations {
  query?: {
    [k: string]: unknown;
  };
}

interface NoParams {}

interface AdminRemoveSetupUser {
  facilityId: string;
  actor: {
    id: string;
    userId: string;
  };
}

interface AdminUpdateFacility {
  id: string;
  changes: {
    id?: string;
    orgId?: string;
    name?: string;
    displayName?: string | null;
    type?: 'rcfe' | 'arf' | 'groupHome' | 'adp' | 'srf' | 'ffh' | 'sfh' | 'rcfci';
    facilityNumber?: string | null;
    licenseeName?: string | null;
    licenseePhone?: string | null;
    capacity?: number;
    isActive?: boolean;
    isDiscounted?: boolean;
    regionalOffice?: string | null;
    logoFileId?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: 'US-AL' | 'US-AK' | 'US-AZ' | 'US-AR' | 'US-CA' | 'US-CO' | 'US-CT' | 'US-DE' | 'US-FL' | 'US-GA' | 'US-HI' | 'US-ID' | 'US-IL' | 'US-IN' | 'US-IA' | 'US-KS' | 'US-KY' | 'US-LA' | 'US-ME' | 'US-MD' | 'US-MA' | 'US-MI' | 'US-MN' | 'US-MS' | 'US-MO' | 'US-MT' | 'US-NE' | 'US-NV' | 'US-NH' | 'US-NJ' | 'US-NM' | 'US-NY' | 'US-NC' | 'US-ND' | 'US-OH' | 'US-OK' | 'US-OR' | 'US-PA' | 'US-RI' | 'US-SC' | 'US-SD' | 'US-TN' | 'US-TX' | 'US-UT' | 'US-VT' | 'US-VA' | 'US-WA' | 'US-WV' | 'US-WI' | 'US-WY' | 'US-DC';
    postalCode?: string | null;
    country?: 'US';
    createdAt?: string;
    createdBy?: string | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
    [k: string]: unknown;
  };
}

interface AdminUpdateFeatureFlags {
  facilityId: string;
  flags: {
    id: 'documents' | 'documents-facility' | 'documents-staff' | 'documents-resident' | 'documents-incomplete' | 'documents-forms' | 'incident-reports' | 'experiments' | 'move-resident' | 'medication-destruction' | 'narcotics-count' | 'group-by-care-task-list' | 'pharmacy-integration-medication-review' | 'resident-reports' | 'ai-summarize-notes';
    enabled: boolean;
  }[];
}

interface CompleteSignup {
  signupToken: string;
  facilityId?: string;
  firstName: string;
  lastName: string;
  password: string;
}

interface CreateActivityResidents {
  residentIds: string[];
}

interface CreateDocumentFromTemplate {
  templateId: string;
}

interface CreateFacilityUsers {
  facilityUsers: {
    firstName: string;
    lastName: string;
    email: string;
  }[];
}

interface CreatePhones {
  phones: {
    label: string;
    number: string;
    type: 'mobile' | 'home' | 'work' | 'fax' | 'direct' | 'office';
    isPrimary: boolean;
  }[];
}

interface DeactivateResident {
  deactivatedAt: string;
}

interface DuplicateFile {
  fileId: string;
  medicationId: string;
}

interface EditMedication {
  preflight?: boolean;
}

interface FindByEmail {
  email: string;
}

interface HandleLogChanges {
  addedActivities: string[];
  removedActivities: string[];
  existingActivities: string[];
  addedActivityResidents: string[];
  removedActivityResidents: string[];
  existingActivitiesResidents: string[];
  originalForm: {
    endAt: string;
    startAt: string;
    recordedBy: string;
    recordAt: string;
    notes?: string | null;
  };
  batchId: string;
}

interface LinkDocumentTemplatesToPacket {
  /**
   * @minItems 1
   */
  documentTemplateIds: string[];
}

interface LinkDocumentsToPacket {
  /**
   * @minItems 1
   */
  documentBatchIds: string[];
}

interface LinkLog {
  logType: 'activity' | 'behavior' | 'bp' | 'bs' | 'bodyCheck' | 'bm' | 'food' | 'mood' | 'oxygen' | 'position' | 'respiration' | 'seizure' | 'shower' | 'sleep' | 'note' | 'temp' | 'urine' | 'vital' | 'water' | 'weight';
  logId: string;
}

interface ManageParticipants {
  create?: {
    documentId: string;
    facilityUserId?: string;
    signerIdentity: {
      schemaVersion: 1;
      facilityUserId?: string;
      email: string;
      firstName: string;
      lastName: string;
    };
    role: 'resident' | 'residentRep' | 'facilityRep' | 'physician' | 'editor';
  }[];
  patch?: {
    id: string;
    documentParticipant: {
      role: 'resident' | 'residentRep' | 'facilityRep' | 'physician' | 'editor';
    };
  }[];
  remove?: string[];
}

interface MergeMedicalProfessionals {
  targetMedicalProfessionalId: string;
  sourceMedicalProfessionalIds: string[];
}

interface MergePharmacies {
  targetPharmacyId: string;
  sourcePharmacyIds: string[];
}

interface MoveDocuments {
  toTemplateId: string;
  name: string;
  code: string | null;
  description: string | null;
}

interface MoveResident {
  toFacilityId: string;
  movedAt: string;
  copyLogsSinceMove?: boolean;
}

interface NotifyParticipants {
  scope: 'resident' | 'employee';
  entityId: string;
  participants: {
    firstName: string;
    lastName: string;
    email: string;
    documentIds: string[];
  }[];
}

interface PasswordChange {
  email: string;
  currentPassword: string;
  newPassword: string;
}

interface RegisterFcmToken {
  fcmToken: string;
}

interface RemoveFile {
  fileId: string;
}

interface RenewDocument {
  copyFormData?: boolean;
}

interface RenewSigningToken {
  participantRenewals: {
    id: string;
    duration: {
      value: number;
      units: string;
    };
  }[];
}

interface RequestNewInvitation {
  signupToken: string;
  facilityId?: string;
}

interface ResetPwdLong {
  token: string;
  newPassword: string;
}

interface SendResetPwd {
  email: string;
}

interface SignDocumentForm {
  signatureImage: string;
}

interface SignFile {
  skipCache?: boolean;
}

interface UnlinkDocumentTemplatesFromPacket {
  /**
   * @minItems 1
   */
  documentTemplateIds: string[];
}

interface UnlinkDocumentsFromPacket {
  /**
   * @minItems 1
   */
  documentBatchIds: string[];
}

interface UnregisterFcmToken {
  fcmToken: string;
}

interface UpdateMedication {
  preflight?: boolean;
}

interface UpdateProposedChange {
  proposedChangeId: string;
}

interface UpdateRoles {
  roleIds: string[];
}

interface UploadAttachedFile {
  url: string;
  filename: string;
}

interface UploadDocumentFile {
  documentId: string;
  url: string;
  filename: string;
}

interface UploadPrescriptionPicture {
  imageUrl: string | null;
}

interface UploadProfilePicture {
  imageUrl: string;
}

interface ValidateResetToken {
  resetToken: string;
}

interface ValidateSignupToken {
  signupToken: string;
}
