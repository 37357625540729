<ion-header>
  <ion-toolbar color="dashboard-primary">
    <ion-title>What's New</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="min-h-full bg-surface p-6">
    <div class="mx-auto flex max-w-xl flex-col">
      <alc-release
        id="release-1-34"
        title="Release 1.34"
        subtitle="March 25, 2025"
        codename="Selenium"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight type="feature" title="Vital Logs">
          <alc-release-paragraph>
            Alcomy has long supported recording various types of vitals via logs
            like blood pressure, blood sugar, oxygen, and temperature, but many
            of you have asked if we can add a log that brings together all of
            these into a single log. Today we're doing just that with the
            addition of Vital Logs!
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Vital Logs Page"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.34/vital-logs-page.png"
          />

          <alc-release-paragraph>
            Vital logs bring together the 4 log types mentioned above plus the
            new respiration log we are also releasing today (more on that
            below). Furthermore, we are also adding the ability to configure
            which log types should be required and which are optional.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Vital Log Settings"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.34/vital-logs-settings.png"
          />

          <alc-release-paragraph>
            Under the hood we are still recording the individual logs for each
            log type in the vital log, but the vital log groups them together to
            act as a single unit. This allows each log type within the vital log
            to still be viewed in their dedicated pages and reports, but you
            also get the benefit of viewing all 5 log types together under the
            vital logs page and in the vital logs report.
          </alc-release-paragraph>

          <alc-callout type="warning">
            In order to view the 5 logs types together, they must be recorded as
            a vital log. If they are recorded independently they will not show
            up under the vital logs page or report.
          </alc-callout>
        </alc-release-highlight>

        <alc-release-highlight type="feature" title="Repiration Logs">
          <alc-release-paragraph>
            We've added a new respiration log for recording things like beathing
            rate (breaths/minute), depth, and rhythm. As mentioned above, this
            is also one of the log types that will appear under the vital log.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Respiration Log Form"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.34/respiration-log-form.png"
          />
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="Restrict access to allowed IP addresses"
        >
          <alc-release-paragraph>
            You can now restrict access to certain IP addresses listed in the
            new Allowed IP Addresses setting. This is a useful alternative to
            Geofence restricted access, especially for those who use devices
            that do not have GPS (e.g. PCs), and who's internet service provider
            does not provide accurate location information.
          </alc-release-paragraph>

          <alc-release-paragraph>
            If you've ever gotten the dreaded Geofence Violation warning while
            on a PC and the map shows you several miles away from the facility,
            then you've felt this pain, and this feature is for you.
          </alc-release-paragraph>

          <h5>How it Works</h5>

          <alc-release-paragraph>
            To use this feature, you will need to turn on the IP Address Acceess
            setting located in Configuration > Settings > General > Location
            Settings. This will reveal the Allowed IP Addresses setting where
            you can add the IP addresses you want to allow. In most cases, you
            will only need to add the IP address for your facility's network,
            but you have the flexibility to add additional addresses according
            to your needs.
          </alc-release-paragraph>

          <alc-callout class="my-6">
            You can find out what your facility's public IP address is by doing
            a simple Google search for "my ip address" while connected to the
            facility's internet. The IP address will be shown in a little widget
            called "What's my IP"
          </alc-callout>

          <alc-release-image
            class="w-full"
            caption="IP Address Settings"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.34/allowed-ip-setting.png"
          />

          <alc-release-paragraph>
            Once this is configured, you can then turn on Restrict to IP Address
            on any role you want this restriction applied to (similar to what
            you do for restricting to geofence).
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Role IP Address Setting"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.34/role-ip-setting.png"
          />

          <alc-callout class="my-6" type="warning">
            Unless your facility's internet has been setup with a static IP
            address (i.e. one that never changes), your facility's IP will most
            likely change periodically, causing anyone who had access previously
            to lose it. This can be fixed by updating the facility's old IP
            address to the new one in the facility's Allowed IP Addresses
            setting. Note: although this is a viable short term solution, it can
            get tedious really fast, so you may want to look into getting a
            static IP address.
          </alc-callout>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="improvement">
          The table columns (desktop view) for resident medication tasks has
          been reordered to Medication Name, Instructions, Dose, and Inventory.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Updating a destruction item now works
        </alc-release-item>

        <alc-release-item type="bugfix">
          Incident reports were not generating in certain circumstances.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Document template PDFs were not being displayed when trying to view
          them.
        </alc-release-item>

        <alc-release-item type="bugfix">
          The expiring and expired medications pages were not displaying what
          the dashboard issues card was indicating.
        </alc-release-item>

        <alc-release-footer href="" />
      </alc-release>

      <alc-release
        id="release-1-33"
        title="Release 1.33"
        subtitle="March 14, 2025"
        codename="Arsenic"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight
          type="feature"
          title="Documents 2.0: Document Forms"
          [premium]="!featureFlags()['documents-forms']"
        >
          <alc-release-paragraph>
            Today we're excited to release the next evolution of documents:
            Documents 2.0! Documents 2.0 adds a number of capabilities and
            improvements aimed at making document collection easier and more
            efficient. Below, we go over a few highlights, but for a full deep
            dive please reach out to your customer success manager.
          </alc-release-paragraph>

          <h5>Sharing Document</h5>

          <alc-release-paragraph>
            You can now share documents with 3rd-party individuals by adding
            them as a "Document Participant" to individual documents or to a
            group of documents via a document packet. Participants will be
            notified via email and be provided a secure link to the new Document
            Portal page.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Participant Manager"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.33/document-participants.png"
          />

          <alc-callout type="success">
            Pro Tip: Create packets around documents that are typically shared
            together. For example, you could create "Pre-admission" and
            "Admission" packets that contains documents for each stage. You can
            then share these packets at the appropriate time with the right
            individuals.
          </alc-callout>

          <h5>Document Portal</h5>
          <alc-release-paragraph>
            The Document Portal allows participants to provide the necessary
            information needed for each document through 3 different
            experiences. Uploading a file, filling out a PDF form, or filling
            out a Alcomy-created digital form (California only for now).
          </alc-release-paragraph>

          <h6>File Upload</h6>

          <alc-release-paragraph>
            For all documents, participants can upload a file as a way to
            satisfy a document's requirement. This is the most basic form of
            document collection and is best for things like copies of driver's
            license and insurance cards. Word documents, scanned files, or other
            non PDF form files are also common use cases.
          </alc-release-paragraph>

          <h6>PDF Forms</h6>

          <alc-release-image
            class="w-full"
            caption="PDF Form"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.33/pdf-form.png"
          />

          <alc-release-paragraph>
            The next level up for collecting document information is through a
            PDF form. If you add a PDF form to a document, or document template,
            participants will be able to view, fill out, and save that form from
            their browser. There is currently no signing capability with this
            yet, but this planned for a future release. For now the participant
            would have to fill out the form, print it, sign it, scan it back
            onto their devices, and then upload it.
          </alc-release-paragraph>

          <h6>Alcomy-created Digital Forms (California Only)</h6>

          <alc-release-image
            class="w-full"
            caption="Alcomy Digital Form"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.33/document-form-601.png"
          />

          <alc-release-paragraph>
            For California facilities, we have created digital forms for the
            most common state licensing documents (e.g. LIC 601, LIC 602, etc).
            Apart from offering a richer more modern experience for collecting
            information, digital forms allow participants to digitally sign
            them. Once all parties have signed, Alcomy will then generate the
            corresponding PDF document with the signature filled.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Digital Signatures"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.33/document-signature.png"
          />

          <alc-callout type="info">
            We currently building out digital forms for standardized documents
            accepted by the state. California has a lot of these standardized
            documents so we thought it was a good place to start. If your state
            has standardized forms that you would like to see digitized, please
            reach out to your customer success manager.
          </alc-callout>

          <h5>Wrapping Up</h5>

          <alc-release-paragraph>
            The ability to fill out PDF forms or Digital forms (and sign them)
            is not exclusive to 3rd-party participants. Facility users with the
            right permissions can also do this from the document detail page.
          </alc-release-paragraph>

          <alc-release-paragraph>
            For documents with digital forms you will see a new Forms tab in the
            document details page. Documents with PDF forms will open up when
            you view the file
          </alc-release-paragraph>

          <alc-release-paragraph>
            To see the full experience in action reach out to your customer
            success manager to get trained on how to use the new features in
            Documents 2.0.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="Grace Period Settings for Missed Medication and Care Tasks"
        >
          <alc-release-paragraph>
            We've added two new setting that allows you to set a grace period
            for missed medication and care tasks. This grace period is the
            amount of time after the scheduled time that a task must be
            completed before it gets marked missed.
          </alc-release-paragraph>

          <alc-release-paragraph>
            You can configure these settings from the facility settings page. By
            default, the grace period is set to 1 hour.
          </alc-release-paragraph>

          <alc-callout type="info">
            The grace period is set in minutes. For example, if you set the
            grace period to 30 minutes, a medication scheduled for 8AM can be
            marked as missed up until 8:30AM.
          </alc-callout>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="feature">
          A new searchbar has been added to the document tracking page for
          residents, staff, and the global documents module to make it a little
          quicker to find the document you're looking for.
        </alc-release-item>

        <alc-release-item type="improvement">
          You can now resend orders to the pharmacy. This is useful for fax
          orders since they are more susceptible to failure. e.g. a busy fax
          number, fax machine out of paper, etc.
        </alc-release-item>

        <alc-release-item type="change">
          Added mEq/mL and mcg/act as units of measure for medications.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Variety of bug fixes and performance improvements.
        </alc-release-item>

        <alc-release-footer href="" />
      </alc-release>

      <alc-release
        id="release-1-32"
        title="Release 1.32"
        subtitle="November 27, 2024"
        codename="Germanium"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight
          type="feature"
          title="Destruction Report (Custom Reports)"
        >
          <alc-release-paragraph>
            We've added a printable report for medication destructions. This
            report is available through the Report Builder located at Residents
            > Reports.
          </alc-release-paragraph>

          <alc-release-paragraph>
            The report contains two tables: one for medications that are ready
            to be destroyed but the process of destroying them has not been
            started, and the other for destroyed medications.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Printed Destruction Report"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.32/destruction-report.png"
          />
          <alc-callout type="info">
            You will need to create a new custom report with the Narcotic Count
            report turned on.
          </alc-callout>
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="Narcotic Count Report (Custom Reports)"
        >
          <alc-release-paragraph>
            We added a printable report for narcotic counts. This report is
            available through the Report Builder located at Residents > Reports.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Printed Narcotic Count Report"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.32/narcotic-count-report.png"
          />

          <alc-callout type="info">
            You will need to create a new custom report with the Narcotic Count
            report turned on.
          </alc-callout>
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="New Sleep Check Field On Sleep Log"
        >
          <alc-release-paragraph>
            We've gotten requests for the ability to only record whether a
            resident was asleep or awake. To accommodate this, we've made some
            adjustments to the sleep log form.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Sleep Log Sleep Check"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.32/sleep-log-check.png"
          />

          <alc-release-paragraph>
            As you can see above, the form is now divided into two parts: "Sleep
            Check" and "Sleep Report". The "Sleep Check" part has a single
            toggle button to record if someone was asleep or awake. The "Sleep
            Report" contains the same fields as before.
          </alc-release-paragraph>
          <alc-release-paragraph>
            The catch is you can only record one or the other, not both. For
            example, if you record a sleep check, Alcomy will hide the Sleep
            Report and visa versa. We did this because the two are mutually
            exclusive.
          </alc-release-paragraph>
          <alc-release-paragraph>
            For example, it wouldn't make much sense to record a sleep check
            that someone was found awake, but then also allow recording how many
            hours they were asleep, or how many times they were up. At the end
            of the day we felt this would be confusing and repetitive.
          </alc-release-paragraph>
          <alc-callout type="info">
            The sleep report is really meant to be recorded in the morning as an
            assessment of the resident's sleep pattern during the night. It can
            also be used to record periodically throughout the night as to their
            sleep pattern since the last check-in.
          </alc-callout>
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="Behavior Log Notes Requirement Setting"
        >
          <alc-release-paragraph>
            When creating or updating a behavior, you can now choose if you want
            to require notes or not. By default, we've made notes optional
            (unchecked) due to the feedback we have received.
          </alc-release-paragraph>
          <alc-release-image
            class="w-full"
            caption="Behavior Log Notes Requirement Setting"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.32/behavior-require-notes.png"
          />
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="feature">
          On the narcotic count page, we've added pending and completed
          indicators to help you distinguish between inventory items that have
          been counted and those that haven't. The completed indicator will show
          for 1 hour after a count has been completed or resolved.
        </alc-release-item>

        <alc-release-item type="improvement">
          Along with the new status indicators for narcotic counts, we've also
          changed the date time to reflect that of the most recent count; even
          if it is still pending a witness. Previously, Alcomy would only show
          the last "completed/resolved" count.
        </alc-release-item>

        <alc-release-item type="improvement">
          <p class="mt-0">
            If a document does not have a file attached, and you click on it in
            the document list, Alcomy will now take you directly into the
            document details instead of trying to open the file preview.
          </p>
          <alc-callout type="success">
            Pro Tip: If a document does have a file attached, you can still get
            to the document details quickly by long pressing the document item.
            You can also select View Details in the action menu.
          </alc-callout>
        </alc-release-item>

        <alc-release-item type="improvement">
          The care task list on Dashboard > Care is now collapsed by default.
        </alc-release-item>

        <alc-release-item type="improvement">
          In the Report Builder, it was always super annoying to have to uncheck
          all of the facesheet sub reports every time you wanted to create a
          report that wasn't the facesheet. We've changed this so that no
          sub-reports are checked by default.
        </alc-release-item>

        <alc-release-item type="change">
          The day switcher buttons used for going back to the previous day or
          advancing to the next day (found on pages like medication tasks on the
          dashboard) have been brought in closer to the date picker to make them
          more discoverable.
        </alc-release-item>

        <alc-release-item type="change">
          When changing the title or description of a document, the value will
          now be saved when clicking off of the input field instead of auto
          saving 500ms after the user stopped typing. This fixes the bug
          described below.
        </alc-release-item>

        <alc-release-item type="bugfix">
          We fixed a bug where updating the title and description of a document
          would remove trailing spaces or overwrite the current value with what
          was there previously.
        </alc-release-item>

        <alc-release-item type="bugfix">
          The Severity Rating of a behavior log was showing up in the wrong
          table column when generating the Behavior Log report from Residents >
          Reports.
        </alc-release-item>

        <alc-release-footer href="" />
      </alc-release>

      <alc-release
        id="release-1-31"
        title="Release 1.31"
        subtitle="November 20, 2024"
        codename="Gallium"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight type="feature" title="Staff Documents">
          <alc-release-paragraph>
            Staff docs are here! The same documents experience for residents is
            now available for staff. In the Documents Overview page and the
            Documents Library, you'll now see a new sub tab for
            <b>Staff</b>. In the user's profile page, you see a new tab called
            Docs (just like in the resident profile).
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Staff Documents"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.31/Staff%20Documents%20-%20Library.png"
          />

          <alc-callout type="info" class="my-4">
            For our California customers you can install the
            <b>California Licensing Documents for Employees</b> via the
            community library. Contact your customer success rep if you need
            some guidance on this.
          </alc-callout>
        </alc-release-highlight>

        <alc-release-highlight type="feature" title="More Facility Settings">
          <h5>Prep/Give Medication Time Restrictions</h5>

          <alc-release-image
            class="w-full"
            caption="Prep/Give Medication Time Setting"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.31/Prep-Give%20Medication%20Time%20Setting.png"
          />

          <alc-release-paragraph>
            We've added a couple of settings to restrict how much time before or
            after a medication is scheduled to be given that it can be prepped
            or given. For example, if a medication is scheduled to be taken at
            8AM, and you only want to allow 1 hour from that time (9AM) to mark
            it off as given, you can set that up using these settings
          </alc-release-paragraph>
          <alc-callout type="info" class="my-4">
            There is also a permission that allows a user (like an admin) to not
            be bound by these restrictions
          </alc-callout>

          <alc-release-image
            class="w-full"
            caption="Recorded By Override Permission"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.31/Override%20Recorded%20By.png"
          />

          <h5>Upcoming Documents Threshold</h5>

          <alc-release-image
            class="w-full"
            caption="Upcoming Documents Threshold Setting"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.31/Upcoming%20Documents%20Threshold%20Setting%20.png"
          />
          <alc-release-paragraph>
            Upcoming documents are documents that have a due date or expiration
            date that is upcoming. The "threshold" is the number of weeks into
            the future, starting from today, that a document should start to be
            considered as "upcoming". Up until now, the threshold was fixed at 6
            weeks, but in this release we've added a setting for you to change
            that if you want.
          </alc-release-paragraph>
          <alc-release-paragraph>
            For example, if you want documents to only show up under Upcoming
            when their due date or expiration date is within the next 2 weeks,
            you would set the threshold value to 2.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="New Issue Cards on Dashboard Overview"
        >
          <h5>Bowel Movement Issue Card</h5>
          <alc-release-paragraph>
            We've added a new issue card on the dashboard to show the number of
            residents that have not had a bowel movement in a certain number of
            days. The default is set to 3 days, but you can change this value in
            the facility settings.
          </alc-release-paragraph>
          <alc-release-image
            class="w-full"
            caption="Residents without Bowel Movement Issue Card"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.31/No%20Bowel%20Movements%20Issues%20Card%201.png"
          />
          <alc-release-image
            class="w-full"
            caption="Residents without Bowel Movement List"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.31/No%20Bowel%20Movements%20Issues%20Card%202.png"
          />

          <alc-callout type="warning">
            The <b>Days Since Last Bowel Movement</b> setting is different from
            a similar setting for the bowel movement notification.
          </alc-callout>

          <h5>Expiring and Expired Inventory Items Issue Cards</h5>

          <alc-release-paragraph>
            Two additional issue cards for keeping track of inventory items that
            are about to expire and that have expired have been added to the
            Dashboard Overview page.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Expiring and Expired Inventory Items Issue Cards"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.31/Expiring%20and%20Expired%20Inventory%20Items%20Issue%20Cards%20.png"
          />

          <alc-release-paragraph>
            Clicking each card will take you to a page that shows all the
            inventory items (grouped by resident) that are expiring or expired.
            From there you can take action like mark them empty.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="Drug Lookup on Medication Form"
        >
          <alc-release-paragraph>
            We've added a new drug lookup search box to the medication form.
            When you start typing in the box, Alcomy will search the National
            Library of Medicine's (NLM) drug database and show you a list of
            medications; as well as, possible strengths and form types. When you
            select the medication strength, Alcomy will then fill in any
            information provided by the drug database.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Drug Lookup"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.31/Drug%20Lookup.gif"
          />

          <h5>Some Caveats</h5>

          <alc-release-paragraph>
            One thing you'll notice when you do this is Alcomy will lock some
            fields on the form (e.g. brand name and generic name) while keeping
            other fields unlocked. The fields that are locked are ones that are
            objective in nature; meaning, the value could not be any other
            possible value. Generic name is a good example.
          </alc-release-paragraph>
          <alc-release-paragraph>
            On the other hand, fields like strength and dosage form can be
            subjective; meaning, the value could be represented differently. You
            may be asking, how this could be possible? After all, if a
            medication has a strength of, let's say, 1 g, won't it always have a
            strength of 1 g? And the answer is of course yes and no :). Let's
            explain.
          </alc-release-paragraph>
          <alc-release-paragraph>
            Although, it's true that the "amount of the drug" (in this case 1g)
            needs to be what is listed, how we write that out and represent it
            in our system is subjective to the unit of measure used. For
            example, 1 g could be written as 1,000 mg. Both are correct, and
            mean exactly the same thing.
          </alc-release-paragraph>
          <alc-release-paragraph>
            In our testing, we found it was pretty common for pharmacies to
            change the unit of measure from what is officially listed for a
            number of reasons. It could be to better suit their policies and
            procedures (e.g. always representing mass in mg and volume in mL).
          </alc-release-paragraph>
          <alc-release-paragraph>
            It could also be that the pharmacy's system uses a different drug
            database than what Alcomy uses (there are a lot of them) which lists
            the drug in mg. The point is, the exact value of strength is
            subjective, to the unit of measure used.
          </alc-release-paragraph>

          <alc-release-paragraph>
            The same is true for the dosage form. In one of our tests, we found
            that the NLM listed medicinal eye drops with the dosage form
            "Solution", but in Alcomy you can list it as "Solution" or with the
            more common term "Eye Drops", which is what many pharmacies do as
            well.
          </alc-release-paragraph>

          <alc-release-paragraph>
            For this reason, Alcomy's default behavior is to fill these fields
            with what is listed in the drug database, but leave the fields
            unlocked in case you need to change them for some legit reason.
          </alc-release-paragraph>

          <div class="flex flex-col gap-6">
            <alc-callout type="error">
              The drug lookup feature is meant to be a tool to help you fill in
              the medication form faster and more accurately; however, because a
              3rd party is providing the information, Alcomy cannot guarantee
              with 100% certainty that the information is correct. It is still
              up to you to verify that the information is correct based on what
              the pharmacy sends over.
            </alc-callout>

            <alc-callout type="warning">
              The drug lookup feature does not fill in any information regarding
              administration, like schedules and dosing instructions. This
              information is completely subjective to the doctor's orders and
              what is dispensed by the pharmacy.
            </alc-callout>

            <alc-callout type="warning">
              Not all information in the medication form will be filled in. Only
              the information the drug database makes available will be filled
              in.
            </alc-callout>
          </div>
        </alc-release-highlight>

        <alc-release-highlight
          type="bugfix"
          title="Dashboard Metrics Are Not Correct"
        >
          <alc-release-paragraph>
            Ok everyone, we think we've finally fixed the issue with the metrics
            being off on the Dashboard Overview page. We know this was an
            annoying bug for many of you. It was annoying for us too, trying to
            consistently reproduce it in order to track down the root cause.
            But, with a little bit of black magic and sorcery I think we figured
            it out. We're sorry it took so long to fix.
          </alc-release-paragraph>
          <alc-release-paragraph>
            If you have no idea what I'm talking about, that's ok. Don't worry
            about it. "These are not the droids you are looking for..." :)
          </alc-release-paragraph>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="feature">
          You can now upload a word doc or excel file to a document. Currently,
          you can't view them on the browser, because browsers don't have native
          support for viewing these file types, but we are looking into ways to
          temporarily convert them into a PDF for viewing. On mobile, if you
          have an app that can view these files, Alcomy will open the file in
          that app.
        </alc-release-item>
        <alc-release-item type="feature">
          Added a new permission that allows you to restrict who can change the
          Performed By field on a number of forms. e.g. any of the logs, med
          tasks, etc.
        </alc-release-item>
        <alc-release-item type="feature">
          Added a new permission that allows you to restrict who can change the
          Performed By field on a number of forms. e.g. any of the logs, med
          tasks, etc.
        </alc-release-item>
        <alc-release-item type="feature">
          Group Home has been added as a new facility type.
        </alc-release-item>
        <alc-release-item type="improvement">
          We moved some things around in the medication form to make it more
          logical. For example, the schedules now display the dose info first
          followed by times of day, then days, and lastly the start and end date
          for the schedule. This new ordering follows the natural instruction
          for on the script. e.g. "Take 1 tablet by mouth, 2x daily"
        </alc-release-item>
        <alc-release-item type="improvement">
          Alcomy now ensures that an expiration date cannot be set prior to the
          fill date.
        </alc-release-item>
        <alc-release-item type="improvement">
          You can now edit the facility logo.
        </alc-release-item>
        <alc-release-item type="change">
          Date range presets that go into the future (e.g. Next 7 days, Next 30
          days, etc.) now include today's date in the range
        </alc-release-item>
        <alc-release-item type="bugfix">
          Fixed an issue where the narcotic count was not showing the correct
          expected amount.
        </alc-release-item>
        <alc-release-item type="bugfix">
          Printed <alc-state-label path="mar" type="abbreviation" /> now
          displays medication times in the correct chronological order
        </alc-release-item>
        <alc-release-item type="bugfix">
          Meds prepped in the future now correctly show the user's initials with
          the "yellow" background, indicating the medication has been prepped.
        </alc-release-item>
        <alc-release-item type="bugfix">
          Document names that are prefixed with a number now sort as expected.
        </alc-release-item>

        <alc-release-footer href="" />
      </alc-release>

      <alc-release
        id="release-1-30"
        title="Release 1.30"
        subtitle="October 10, 2024"
        codename="Zinc"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight type="feature" title="Activity/Outing Logs">
          <alc-release-paragraph>
            In this release we have added a dedicated log for recording
            activities and outings. It all starts with defining a custom list of
            activities and outings under Configuration > Lists > Activities /
            Outings.
          </alc-release-paragraph>

          <alc-release-paragraph>
            Next, make sure to turn on the Activity/Outing log for whichever
            residents you'll be recording activities / outings for
          </alc-release-paragraph>

          <alc-release-paragraph>
            Finally, open the activity/outing log form the way you typically
            would for any other log. When you do this, you'll notice that the
            form looks a bit different than the typical log form. That's because
            you are able to record multiple activities / outings for multiple
            residents.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Activity/Outing Log Form"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.30/Activites%20-%20Outings.gif"
          />

          <alc-release-paragraph>
            To make this process easier, we've broken the form into multiple
            parts/steps. These steps are as follows:
          </alc-release-paragraph>

          <ol class="space-y-2">
            <li>Choose the type of log you want (either Activity or Outing)</li>
            <li>
              Based on your selection from step 1, you will then be asked to
              select one or more activities / outings that one or more residents
              participated in.
            </li>
            <li>
              You'll then be asked to select one or more residents that
              participated in the selected activities / outings from step 2.
            </li>
            <li>
              Record specifics like the start and end time and any notes about
              the activities the residents participated in
            </li>
          </ol>

          <alc-callout class="my-4" type="info">
            Most of the time you'll be recording one or more residents for a
            single activity or outing, but if you ever need to record multiple
            activities or outings, that occur at the same time, for multiple
            residents, the ability to do so is there.
          </alc-callout>

          <alc-release-paragraph>
            One important thing to note here is that Alcomy will still create a
            single log entry for each activity for each resident. For example,
            if you record 2 activities for 2 residents, each resident will have
            2 log entries (one for each activity) listed in their
            activity/outings page.
          </alc-release-paragraph>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="improvement">
          Added task completion indicator to care tasks
        </alc-release-item>

        <alc-release-item type="change">
          It is now no longer possible to set a medication inventory item's fill
          date into the future.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed an issue with the legacy reports not generating and being met
          with a never ending spinner.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a bug that caused sending Rx orders via email to fail.
        </alc-release-item>

        <alc-release-item type="bugfix">
          The issues on the Dashboard Overview page were not showing the correct
          numbers. (Note: document metrics are still off in certain
          circumstances)
        </alc-release-item>

        <alc-release-item type="bugfix">
          Not able to mark an inventory item as not empty after it was already
          marked empty.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Empty inventory items were showing up in places they shouldn't have
          (like the switch inventory picker).
        </alc-release-item>
        <alc-release-item type="bugfix">
          In some circumstances, when updating a medication, the New tag would
          show instead of the Updated tag. This has been fixed.
        </alc-release-item>

        <alc-release-footer href="" />
      </alc-release>

      <alc-release
        id="release-1-29"
        title="Release 1.29"
        subtitle="August 27, 2024"
        codename="Copper"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight type="feature" title="Medication Destruction">
          <alc-release-paragraph>
            Finally! You can now destroy medications in Alcomy. Just like
            Narcotics, this is a beta feature. If you would like to try it out,
            please reach out to Alcomy support to get access and trained.
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Dashboard Medication Destruction"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.29/dashboard-destruction.png"
          />

          <alc-release-paragraph>
            In short, step 1 is to add medications to the Pending Destruction
            list on the new <b>Destruction</b> page located at
            <b>Dashboard > Meds > Destruction</b> (This is kind of like your
            stash of medications that need to be destroyed). You do can this in
            3 ways:
          </alc-release-paragraph>

          <ul class="space-y-2">
            <li>
              By marking an omitted medication to be destroyed on the Reason for
              Omission form.
            </li>
            <li>
              By marking an entire inventory item to be destroyed from the
              medication inventory page in the resident section.
            </li>
            <li>
              By adding a medication to be destroyed directly on the Destruction
              page. This is nice for those random medications you find on the
              floor and you don't know what they are or who they belong to.
            </li>
          </ul>

          <alc-release-paragraph>
            Step 2 comes when you are ready to destroy those items in Pending
            Destruction. Simply select some or all of them, click the Create
            Destruction button, and destroy and witness.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight type="feature" title="New Report Builder">
          <alc-release-paragraph>
            When it comes to printed reports there are three things that get
            requested constantly.
          </alc-release-paragraph>

          <ol class="space-y-2">
            <li>
              The ability to print different variations and combinations of
              reports available.
            </li>
            <li>
              The ability to print a report for multiple residents at the same
              time.
            </li>
            <li>The ability to add your own logo and branding to reports.</li>
          </ol>

          <alc-release-paragraph>
            Well, we are happy to report (no pun intended) that in this release
            we've added the first version of our custom report builder that
            allows you to do all three. To get started, head on over to the new
            reports page located at <b>Residents > Reports</b>
          </alc-release-paragraph>

          <alc-release-image
            class="w-full"
            caption="Custom Report Builder"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.29/custom-reports-setup.gif"
          />

          <alc-release-paragraph>
            When you're there, click the plus button to be presented with the
            report builder. The report builder allows you to toggle on/off the
            various sub-reports you want to include in your report and rearrange
            them however you'd like. Some reports have additional configuration
            options like a date range or checkboxes for showing/hiding certain
            sections within the sub-report.
          </alc-release-paragraph>

          <alc-release-paragraph>
            In desktop, you'll also see a preview of the layout for the report
            to the right of the sub-report options.
          </alc-release-paragraph>

          <alc-release-paragraph>
            When you're finished, give the report a name and optional
            description and voilà! Your custom report is built. Next, it's time
            to generate it.
          </alc-release-paragraph>

          <alc-callout class="my-4" type="success">
            Pro Tip: Create a custom report for the things your licensing rep
            typically asks for. Then, when they show up, you can generate the
            report for the residents they want. The faster you get them what
            they want, the faster you can get them the heck outta there! :)
          </alc-callout>

          <alc-release-image
            class="w-full"
            caption="Generating Custom Reports"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.29/custom-reports-generation.gif"
          />

          <alc-release-paragraph>
            On the reports page, you will see all your custom reports. Click
            <b>Generate Report</b> on one of your reports. Next, select the
            residents you want to generate the report for, and click Submit.
          </alc-release-paragraph>

          <div class="flex flex-col gap-6">
            <alc-callout type="success">
              Pro Tip: To save on costs, consider downloading your report as a
              PDF rather than printing them. Several of our customers have
              reported saving $250+ a month just on paper and ink costs by
              employing this technique. Remember, you can always print the PDF
              later if you need to.
            </alc-callout>

            <alc-callout type="warning">
              <p>
                One caveat to be aware of is that the more sub-reports you add
                to a report, and the more residents you generate a report for,
                the longer it will take to generate due to the sheer amount of
                data that needs to be collected. We are working on ways to
                possibly run this process in the background, but we're not quite
                there yet.
              </p>
            </alc-callout>

            <alc-callout type="info">
              For now the old reports still exists where they are, but will be
              removed in a future release.
            </alc-callout>
          </div>
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="Merge Pharmacies and Medical Professionals"
        >
          <alc-release-paragraph>
            Do you have some pharmacies or medical professionals that were
            created as duplicates? Well, now you can merge them together so that
            you only have one.
          </alc-release-paragraph>
          <alc-release-paragraph>
            To do this, first go to <b>Configuration > Contacts</b>. Once there,
            either long press on a pharmacy (or medical professional) or choose
            <b>Select Item</b> from the item's action menu (3 dots). This will
            turn on multi-select mode.
          </alc-release-paragraph>
          <alc-release-image
            class="w-full"
            caption="Custom Report Builder"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.29/pharmacy-merge.gif"
          />
          <alc-release-image
            class="w-full"
            caption="Custom Report Builder"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.29/medical-professional-merge.gif"
          />
          <alc-release-paragraph>
            Next, select all the items that are duplicates and click the
            multi-select action menu (6 dots) and choose <b>Merge Records</b>.
            You will be presented with the merge form.
          </alc-release-paragraph>
          <alc-release-paragraph>
            Basically, you need to select which pharmacy (or medical
            professional) should be the primary one. This is typically the one
            that has the most information filled in. Alcomy will only fill in
            missing fields, and will not overwrite ones that already have data.
          </alc-release-paragraph>
          <alc-release-paragraph>
            All that's left to do is click the submit button and watch the
            duplicates disappear.
          </alc-release-paragraph>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="improvement">
          Added eye color and height to the Basic Information section on the
          Facesheet.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added mortuary and mortuary phone number to the Medical Services
          section on the Facesheet.
        </alc-release-item>
        <alc-release-item type="improvement">
          Added OTC label to inventory item when medication is OTC
        </alc-release-item>

        <alc-release-item type="improvement">
          You can now upload a PDF for the medication scripts.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added the user that last updated a medication, as well as the date and
          time of the update, to the Medication Detail page
        </alc-release-item>

        <alc-release-item type="improvement">
          Added a new field on medications called "Originally Prescribed On" to
          record the date the medication was first prescribed.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added a prompt when undoing a medication pass, in order to prevent
          accidental undos.
        </alc-release-item>
        <alc-release-item type="improvement">
          Added the ability to update the pharmacy from the pharmacy information
          modal.
        </alc-release-item>

        <alc-release-item type="change">
          Updated the link for the help library button in the main menu which
          now sends you to the new help library.
        </alc-release-item>

        <alc-release-item type="bugfix">
          After correcting an email address for a user, the system wouldn't send
          signup emails to the new address.
        </alc-release-item>

        <alc-release-item type="bugfix">
          The ability to order medications was incorrectly tied to the "update
          medications" permissions. There is now a dedicated permission for
          flagging medications to be ordered.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Setting the end date on a medication schedule after tasks have already
          been created, doesn't remove tasks past the end date.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Creating a document template and setting it to auto-create for
          everyone didn't actually create documents for everyone
        </alc-release-item>

        <alc-release-footer
          href="https://doc.clickup.com/2369496/d/h/289yr-2467/df5d518c915ba17"
        />
      </alc-release>

      <alc-release
        id="release-1-28"
        title="Release 1.28"
        subtitle="June 21, 2024"
        codename="Nickel"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-paragraph>
          In this release we've made a number of improvements to the ordering
          experience.
        </alc-release-paragraph>

        <alc-release-highlight
          type="feature"
          title="New Order Review Experience"
        >
          <alc-release-paragraph>
            Firstly, the Pending orders page has been redesigned and renamed to
            just <b>Orders</b>. The page now shows a list of orders instead of a
            list of order items (which previously could have spanned multiple
            orders). If the medications being ordered are to different
            pharmacies, a separate order for each pharmacy will be created.
          </alc-release-paragraph>

          <alc-release-paragraph>
            You'll also see some new status indicators so you can quickly see
            which orders were submitted successfully, which ones were received
            by you, which ones you cancelled, and which ones had failed and why.
            You can also filter the orders down by status and date range
            allowing you to go back and review your previously placed orders.
          </alc-release-paragraph>

          <alc-release-image
            caption="Medication Orders Page"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/medication-orders-list-desktop.png"
          />

          <alc-release-paragraph>
            Clicking on an order takes you to the new <b>Order Details</b>
            page, where you'll see the individual medications that were part of
            that order, grouped by resident (similar to what you saw
            previously).
          </alc-release-paragraph>

          <alc-release-image
            caption="Medication Order Details Page"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/medication-order-details-desktop.png"
          />

          <alc-release-paragraph>
            If you have eagle eyes, you may have noticed from the screenshots
            that you can now delete orders and order items. Finally! You can
            also view the actual order PDF Alcomy generates and sends to the
            pharmacy, and (as we hinted earlier) you can mark an order (or order
            item) as cancelled.
          </alc-release-paragraph>

          <alc-callout type="warning">
            This is just a status change for now. Alcomy doesn't actually send
            the cancel request to the pharmacy. You'll have to notify them
            manually until we can add this functionality.
          </alc-callout>
        </alc-release-highlight>

        <alc-release-highlight type="feature" title="Changing Ordering Method">
          <alc-release-paragraph>
            For pharmacies that allow and prefer orders to be sent via email,
            this option is now available. This can be done by setting the new
            <b>Preferred Ordering Method</b> field on the pharmacy form to
            <b>Email</b> instead of <b>Fax</b> and entering an email address for
            the pharmacy. That's it! Orders to that pharmacy will now be sent
            via email.
          </alc-release-paragraph>

          <alc-release-image
            class="mx-auto w-full sm:w-3/4"
            caption="Preferred Ordering Method Field"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/ordering-method-field.png"
          />

          <alc-callout type="error">
            <p>
              Sending PHI information through email is kind of a touchy subject
              with a lot of opinions and gray area due to conflicting
              information. Some will say, "email is insecure", or "email is not
              HIPAA compliant". And while both of those statements CAN be true,
              it is equally true that email CAN be made secure and HIPAA
              compliant. Read on to see what HHS says and what Alcomy does to
              make sure our emails are both secure and HIPAA compliant.
            </p>

            @if (expandEmailDisclaimer) {
              <div @expansion>
                <p>
                  If you would like to first know what HHS says about this
                  topic, here are a couple of links.
                </p>
                <ul class="space-y-2">
                  <li>
                    <a
                      href="https://www.hhs.gov/hipaa/for-professionals/faq/2006/does-the-security-rule-allow-for-sending-electronic-phi-in-an-email/index.html"
                    >
                      Does the Security Rule allow for sending electronic PHI
                      (e-PHI) in an email or over the Internet?
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.hhs.gov/hipaa/for-professionals/faq/570/does-hipaa-permit-health-care-providers-to-use-email-to-discuss-health-issues-with-patients/index.html"
                    >
                      Does the HIPAA Privacy Rule permit health care providers
                      to use e-mail to discuss health issues and treatment with
                      their patients?
                    </a>
                  </li>
                </ul>
                <p>
                  To transmit securely, Alcomy uses a third party email provider
                  that encrypts our emails as they travel to the recipient's
                  email server. However, this is not enough. The recipient's
                  email service needs to also store the email in an encrypted
                  state. Some do, and some don't. It's really outside of our
                  control.
                </p>
                <p>
                  This is why we don't actually send PHI information directly in
                  the email in the first place. Instead, we send a secure link
                  to the recipient which allows them to view and download (in
                  the case of files) the PHI information which is encrypted and
                  stored on our secure servers.
                </p>
                <p>
                  In this way, the link acts as a type of authentication token
                  that you grant to the email's recipient. As an extra bit of
                  protection, we put an expiration on the link making it only
                  good for a limited time.
                </p>
                <p>
                  You still have to be careful that you are not granting an
                  unauthorized individual access to PHI information through
                  Alcomy, but this is true no matter what system (paper or
                  digital) you are using. When it comes to ordering medications;
                  though, we hope you can trust your pharmacist :). At the end
                  of the day it's up to you, them, and the resident's power of
                  attorney to determine how PHI is being shared.
                </p>
              </div>
            }

            <div class="flex justify-end">
              <ion-button
                fill="clear"
                shape="round"
                color="danger"
                (tap)="expandEmailDisclaimer = !expandEmailDisclaimer"
                [style.touch-action]="'auto'"
              >
                @if (!expandEmailDisclaimer) {
                  Read More
                } @else {
                  Close
                }
              </ion-button>
            </div>
          </alc-callout>
        </alc-release-highlight>

        <alc-release-highlight type="feature" title="Bulk Order Flagging">
          <alc-release-paragraph>
            On the resident's medication list page, you can now select multiple
            medications and flag them to be ordered. We'll be adding more bulk
            actions in the coming days.
          </alc-release-paragraph>

          <alc-release-image
            class="mx-auto w-full sm:w-3/4"
            caption="Bulk Order Flagging"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/medication-list-multi-select.png"
          />

          <alc-release-paragraph />
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="Group Care Tasks By Resident (Beta)"
        >
          <alc-release-paragraph>
            Many of you have asked for the ability to group tasks by resident
            instead of by time. In this release we've added this new view to
            care tasks as a beta feature.
          </alc-release-paragraph>
          <alc-release-paragraph>
            As a reminder, beta features are turned on for those who are
            interested in trying out early release software in the "proof of
            concept" phase, and can give feedback to make it better. If that
            sounds like something you'd like to do, please reach out, and we'll
            turn it on for your account. Here's a look at the new view.
          </alc-release-paragraph>

          <alc-release-image
            class="mx-auto w-full sm:w-3/4"
            caption="Care Tasks Grouped by Resident"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/care-tasks-by-resident.png"
          />

          <alc-release-paragraph />
        </alc-release-highlight>

        <alc-release-highlight
          type="feature"
          title="Move a Resident to Another Facility"
        >
          <alc-release-paragraph>
            We added the ability for you to move a resident from one facility to
            another. Alcomy support used to have to do this manually for you,
            but not anymore. You now have the power to do this yourself at your
            convenience. Simply go to the resident's facesheet and open the
            action menu for <b>Facility Information</b>. You will see a new
            action called <b>Move To Different Facility</b>.
          </alc-release-paragraph>

          <alc-release-image
            class="mx-auto w-full sm:w-3/4"
            caption="Move Resident Action"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/move-resident-option.png"
          />

          <alc-release-paragraph>
            In the Move Resident form you will be presented with the following
            steps.
          </alc-release-paragraph>

          <ol class="space-y-2">
            <li>Select the facility you want to move the resident to</li>
            <li>
              Choose the date and time you want this move to take place (this is
              important as we'll see later).
            </li>
            <li>
              Confirm the move. This action cannot be undone, so we require you
              to explicitly confirm it.
            </li>
          </ol>

          <alc-release-image
            caption="Move Resident Form"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.28/move-resident-form.png"
          />

          <alc-release-paragraph>
            When you move a resident, Alcomy automatically performs the
            following actions:
          </alc-release-paragraph>

          <ol class="space-y-2">
            <li>
              Sets the resident in the original facility to <b>Discharged</b>
            </li>
            <li>
              Removes any medication or care tasks scheduled after the move
              date/time.
            </li>
            <li>
              Removes logs and appointments recorded or scheduled after the move
              date/time.
            </li>
            <li>
              Copies the resident profile to the target facility, including all
              facesheet information.
            </li>
            <li>
              Copies the resident's medications and care plan items to the
              target facility, but sets the start date for any schedules to the
              move date/time..
            </li>
            <li>
              Copies any non-empty centrally stored inventory to the target
              facility.
            </li>
            <li>
              Generates medication and care tasks based on their respective
              schedules.
            </li>
          </ol>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="feature">
          Added Nebulizer/Nebulization as a new medication form.
        </alc-release-item>

        <alc-release-item type="feature">
          Added Prescription Undelivered as a new omit reason.
        </alc-release-item>

        <alc-release-item type="feature">
          You can now tell Alcomy to create multiple all-day tasks when creating
          a care task.
        </alc-release-item>

        <alc-release-item type="feature">
          It is now possible to mark an inventory item as not empty.
        </alc-release-item>

        <alc-release-item type="improvement">
          Better prompts when a user is outside the facility's geofence.
        </alc-release-item>

        <alc-release-item type="improvement">
          Dramatically improved the loading performance of care tasks.
        </alc-release-item>

        <alc-release-item type="improvement">
          Made the security keyword shorter when deleting a medication. It's not
          as obnoxious as it used to be.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a bug where observation notes were not displaying.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a typo in the title for the simplified
          <alc-state-label path="mar" type="abbreviation" /> report.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a bug that didn't allow you to create a standalone document from
          scratch.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a bug that did not remove discontinued PRNs from the Manual Med
          Sheet.
        </alc-release-item>

        <alc-release-footer />
      </alc-release>

      <alc-release
        id="release-1-27"
        title="Release 1.27"
        subtitle="March 13, 2024"
        codename="Cobalt"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight type="feature" title="Narcotic Counts (Beta)">
          <alc-release-paragraph>
            Today we are releasing the much anticipated
            <b>Narcotic Counts</b> feature as a <b>beta</b> feature. Beta
            features should be fairly stable, but may have bugs or may need to
            be redesigned based on feedback and real world usage. We will be
            rolling out beta features, like Narcotic Counts, to a limited group
            of customers that are part of our beta program. Our goal is to get
            as much feedback as we can, test things in the "real world", and
            make changes where needed before releasing to everyone.
          </alc-release-paragraph>

          <alc-release-paragraph>
            If you are chomping at the bit to try out Narcotic Counts and would
            like to be a beta tester, please reach out to Jake or Alcomy support
            to get access and trained.
          </alc-release-paragraph>

          <alc-release-image
            caption="Narcotic Counts on the Dashboard"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/narcotic-counts.png"
          />
        </alc-release-highlight>

        <alc-release-highlight
          type="improvement"
          title="Better Omit All Experience"
        >
          <alc-release-paragraph>
            We made the omit all experience a lot better. Previously, when you
            did an Omit All, Alcomy would display a separate modal for every
            medication being omitted. This forced users to enter the same omit
            reason and notes over and over again.
          </alc-release-paragraph>

          <alc-release-paragraph>
            Now, we present you with the omit medication modal once and only
            require you to enter the reason and notes once. Below the reason
            information we list out each medication and the amount fields that
            need to be filled out for each. However, we're not leaving you
            completely without help here.
          </alc-release-paragraph>

          <alc-release-image
            caption="Omit All Modal"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/bulk-omit.png"
          />

          <alc-release-paragraph>
            For medications whose dose is a simple value, Alcomy can determine
            the correct quantity and strength and fill them in for you. It's
            only when we are dealing with dose ranges or dose options (which
            describe a spectrum of "possible" doses) that Alcomy cannot make
            this determination without user involvement.
          </alc-release-paragraph>

          <alc-release-paragraph>
            The good news is, dose ranges and dose options are far less common
            than the traditional single dose instruction, so the chances of this
            being a cumbersome process should be pretty low. Of course all
            milage may vary.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight type="improvement" title="Compact Appointments">
          <alc-release-paragraph>
            The Appointments widget on the Dashboard Overview page has been
            given a slight redesign to make it more compact. Specifically, we
            made each appointment item expandable and hid away the
            description/notes information within the expansion panel.
          </alc-release-paragraph>

          <alc-release-paragraph>
            When it becomes relevant to see those additional details you can
            simply expand the item to see them.
          </alc-release-paragraph>

          <alc-release-image
            caption="Compact Appointments on Dashboard"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/condensed-appointments.png"
          />

          <alc-release-paragraph>
            We also changed how many appointments get displayed initially. Any
            appointments after the third appointment will be hidden, with the
            ability to 'Show All' if desired.
          </alc-release-paragraph>
          <alc-release-paragraph>
            We made these changes to make scrolling the Overview page more
            manageable. We found that for facilities that have a lot of
            appointments with long descriptions, a lot of scrolling was needed
            to see the rest of the page.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight
          type="improvement"
          title="Medication Details Facelift"
        >
          <alc-release-paragraph>
            First off, the Inventory card on the <b>Medication Details</b> page
            is no more! Nuked! Gone! We have replaced it with it's own dedicated
            <b>Inventory</b> sub tab. This tab displays a typical list view of
            all your centrally stored inventory items with the ability to drill
            down into a dedicated details page for each item. We feel like this
            is a much better and consistent experience than the card switching
            behavior employed previously. Let us known your thoughts.
          </alc-release-paragraph>

          <alc-release-image
            caption="Medication Inventory Sub Tab"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/medication-details-inventory.png"
          />

          <alc-release-image
            caption="Inventory Item Details Page"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/inventory-item-details.png"
          />

          <alc-release-paragraph>
            The rest of the medication information that you are used to seeing
            is now in the <b>Info</b> sub tab but with a few tweaks to the
            design. We've combined the <b>Additional Information</b> card into
            the main medication card and improved the look and layout of the
            information displayed within. The Schedules card remains but with
            the new layout applied.
          </alc-release-paragraph>

          <alc-release-image
            caption="Medication Info Sub Tab"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/medication-details-info.png"
          />
        </alc-release-highlight>

        <alc-release-highlight
          type="improvement"
          title="Consistent Amount Recording Experience"
        >
          <alc-release-paragraph>
            The experience of recording the amount of medication given or
            omitted has been improved across the app. You currently do this in
            places like giving a PRN, omitting a medication task, and giving a
            medication where the dose is not a fixed amount (e.g. When dose is
            of type range or option). Another time you are required to give an
            amount is when marking a medication prepped, given, or omitted from
            the digital <alc-state-label path="mar" type="abbreviation" /> page
            or Missed Medication Tasks page.
          </alc-release-paragraph>

          <alc-release-paragraph>
            Previously, the experience wasn't consistent. For example, when
            giving a PRN, you could record multiple amounts. In contrast, when
            marking a medication task as Given from the Missed Medication Tasks
            page, multiple amounts wasn't supported. Additionally, recording an
            amount for a medication with a ranged dose or option dose yielded a
            different experience altogether.
          </alc-release-paragraph>

          <alc-release-image
            caption="Unified Amounts Control"
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.27/amounts-improvements.png"
          />

          <alc-release-paragraph>
            In this release we've made considerable strides to make this "amount
            recording" experience consistent and unified across the app. Some of
            the underlining capabilities of this effort include:
          </alc-release-paragraph>

          <ul class="my-3 space-y-2 text-dark">
            <li>
              Indicating which inventory item you are drawing from. This is a
              new capability that brings us closer to being able to offer full
              inventory tracking capabilities (a highly requested feature).
            </li>
            <li>
              The ability to record multiple amounts. This is important when you
              need to draw from multiple inventory items with differing unit
              strengths in order to satisfy the required dose.
            </li>
            <li>
              Robust validation logic. This ensures that the amount recorded is
              permitted by the configured dose.
            </li>
          </ul>
        </alc-release-highlight>

        <alc-release-highlight type="bugfix" title="Log Dependencies for PRNs">
          <alc-release-paragraph>
            PRNs that are configured with a log dependency will now require the
            log to be recorded before a PRN can be given in Alcomy. Ironically,
            it has always been possible to setup a PRN medication to require a
            log; however, from some crazy reason we just didn't prompt you to
            record it when giving a PRN.
          </alc-release-paragraph>
          <alc-release-paragraph>
            Not sure how that slipped by the radar, but we have corrected that
            injustice by adding a Record Log button to the Give PRN form. You
            will need to record the log in order to get dosing information and
            finally submit the PRN form.
          </alc-release-paragraph>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="feature">
          You can now add, view, and delete a medication script image directly
          from the medication detail page.
        </alc-release-item>

        <alc-release-item type="feature">
          Added facility type and regional office to facility info card
        </alc-release-item>

        <alc-release-item type="feature">
          When creating a behavior, you can now check options to require an
          antecedent and consequence when recording the log.
        </alc-release-item>

        <alc-release-item type="feature">
          For our ARF customers, we added a new field to the facility form for
          specifying your regional office.
        </alc-release-item>

        <alc-release-item type="feature">
          Added eye drops as a new medication form.
        </alc-release-item>

        <alc-release-item type="improvement">
          We added dedicated icons to represent each medication form and placed
          them next to the medication name across the app.
        </alc-release-item>

        <alc-release-item type="improvement">
          We added a help guide for medication schedules within the
          <b>Routine Schedule Information</b> form divider in the Medication
          Form. Tap the form divider to expand the help guide.
        </alc-release-item>

        <alc-release-item type="improvement">
          Alcomy now calculates and displays the quantity and strength
          representation of the dose on the medication task card (or the
          medication task table if you are on desktop).
        </alc-release-item>

        <alc-release-item type="change">
          When holding or discontinuing a medication, it is now required to
          include a reason.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a problem in the authentication system that was causing users to
          get logged out randomly.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed an issue where iOS devices where showing blue text instead of
          black.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Reloading the facility roles page no longer results in an empty
          screen.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Sending an announcement to yourself no longer results in an error.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a number of layout and metric issues within the resident's Log
          Overview page.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed an issue where trying to upload gigantic images resulted in an
          error.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed an issue where California document templates were showing for
          non-California facilities
        </alc-release-item>

        <alc-release-footer />
      </alc-release>

      <alc-release
        id="release-1-26"
        title="Release 1.26"
        subtitle="February 14, 2024"
        codename="Iron"
      >
        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight type="feature" title="Resident Document Packets">
          <alc-release-paragraph>
            Prior to this release, packets were only created at the document
            template level. In this release, we bring packets to the resident's
            documents tab. This allows you to organize documents by adding them
            to a packet.
          </alc-release-paragraph>
          <alc-release-paragraph>
            Furthermore, you can now set an auto-create rule on a template
            packet which tells Alcomy who should have a packet automatically
            created for them. By default this rule is set to "Nobody
            (Optional)", which means don't create this packet automatically for
            anyone. You can manually create a packet from a template from within
            the resident's "Docs" tab.
          </alc-release-paragraph>
          <alc-release-paragraph>
            If you want Alcomy to automatically create a packet for all
            residents you can set "Auto-create for" to "Everyone." If you have
            any questions about this feature, or would like to learn more,
            please reach out to your customer success manager at Alcomy to get
            trained.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight
          type="improvement"
          title="Printed MAR Improvements"
        >
          <alc-release-paragraph>
            We added a simplified MAR report for those who want a more condensed
            format when printing the MAR. This report can be printed in the same
            place as the regular MAR report.
          </alc-release-paragraph>

          <alc-release-paragraph>
            We also added the Rx number for the currently selected inventory
            item to the MAR reports for those who need that information.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight
          type="improvement"
          title="Urine Log Improvements"
        >
          <alc-release-paragraph>
            We added greater flexibility to the urine log. You can now record
            whether a resident urinated or not and whether a catheter was used.
            When specifying a catheter, the amount information and color are
            required.
          </alc-release-paragraph>

          <alc-release-paragraph>
            When simply indicating that the resident urinated without a
            catheter, amount information is optional. Lastly, if you indicate
            that the resident did not urinate, the amount fields will be
            disabled.
          </alc-release-paragraph>
        </alc-release-highlight>

        <alc-release-highlight
          type="bugfix"
          title="Editing a medication no longer resets medication task statuses"
        >
          <alc-release-paragraph>
            We've had a number of reports where editing a medication
            (specifically "Correcting a Mistake") would reset the status on
            medication tasks. We have fixed the underlying issue and have
            restored any records that may have had their statuses affected by
            this bug.
          </alc-release-paragraph>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="feature">
          Added a Conservator checkbox to the resident's contact form and
          Conservatorship designation to the facesheet.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added the resident's image and date of birth to the Resident
          Medication Tasks page on the Dashboard.
        </alc-release-item>

        <alc-release-item type="improvement">
          Add father and mother as resident contact relationship options
        </alc-release-item>

        <alc-release-item type="improvement">
          You can now set the resident's admission date into the future.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added "Memory" as medication type for medications that treat memory
          related illnesses.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added specific icons for distinguishing between medication forms in
          the medication list.
        </alc-release-item>

        <alc-release-item type="improvement">
          You can now choose between DNR or POLST as the primary end of life
          order.
        </alc-release-item>

        <alc-release-item type="improvement">
          Add phone number to the resident's insurance information.
        </alc-release-item>

        <alc-release-item type="improvement">
          Various performance and stability improvements
        </alc-release-item>

        <alc-release-item type="bugfix">
          It is no longer possible to create a medication schedule with no
          times. Previously this would remove all tasks for that schedule.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Paragraphs for resident contact notes and care task descriptions now
          display correctly
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a bunch of issues where dates are off by a day
        </alc-release-item>

        <alc-release-item type="bugfix">
          Fixed a bug in the Medical Professional Info modal where residents
          were not being displayed.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Adding, removing, or editing resident contact phone numbers now
          updates in the UI without a page refresh.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Saving an incident report when no email address is configured for
          sending used to cause an error. This is now fixed.
        </alc-release-item>

        <alc-release-item type="bugfix">
          A variety of other small bug fixes and improvements
        </alc-release-item>

        <alc-release-footer />
      </alc-release>

      <alc-release
        id="release-1-25"
        title="Release 1.25"
        subtitle="December 14, 2023"
        codename="Manganese"
      >
        <h2 class="mt-10 text-center font-bold">
          Getting Ready for Documents 2.0
        </h2>

        <div class="text-dark">
          <p>
            Today's release is all about incremental updates before we launch
            Documents 2.0, which will bring digital forms and signing to
            licensing docs (starting with California). Yay! Before releasing
            that behemoth of a feature, we wanted to get some smaller features
            and stability improvements out the door.
          </p>

          <h4 class="font-medium">Our Development Process</h4>

          <p>
            The features in this release may seem pretty basic to many of you,
            with some functionality that is clearly missing. This doesn't mean
            we don't have plans to improve it and make it better. Far from it.
            It's just due to our "agile" and "iterative" development process
            that many tech companies use today, like Google and Facebook. i.e.
            Rather than waiting until everything is perfect before releasing a
            feature, we release it as soon as we believe it can start adding
            value. This provides 2 major benefits:
          </p>

          <ol class="space-y-2">
            <li>
              It allows us to get features into your hands a lot sooner, so that
              you can start getting value from them, no matter how small.
            </li>
            <li>
              It allows us to get feedback from users much earlier in the
              development process, which in turn, allows us to iterate on our
              design and functionality before getting too far in the weeds.
            </li>
          </ol>

          <p>
            A good analogy to help illustrate this is: it's easier to turn a
            canoe than it is to turn a battleship. We hope you will see these
            features as just the start of much larger feature initiatives and a
            way to start a dialog around them. Please reach out to us with
            feedback and ideas as they come to you.
          </p>

          <h4 class="font-medium">Personal Message</h4>

          <p>
            As we approach the end of the year, we express our love and
            gratitude to all of you for the exceptional work that you do in
            caring for your residents. We feel very blessed to be your premiere
            partner in that endeavor. A lot of great things are in the works for
            2024, and we can't wait to share them with you. Until then, we wish
            you and your families a Merry Christmas and a Happy New Year!
          </p>
          <p class="mt-12">Sincerely,</p>
          <p>The Alcomy Team</p>
        </div>

        <h2 class="mt-10 text-center font-bold">Release Highlights</h2>

        <alc-release-highlight type="feature" title="Resident Groups">
          <alc-release-paragraph>
            We've added the ability to organize residents into groups. When you
            go into the Residents section, you'll notice a new Groups tab, where
            you can create new groups and assign residents to them. We've also
            added a Groups card to the resident's facesheet, similar to the
            Groups card in the user's profile page. From there, you can manage
            which groups the resident is a member of.
          </alc-release-paragraph>
          <alc-release-paragraph>
            Continuing on, we've also added a resident group filter to the Meds,
            Care and Notebook tabs on the Dashboard, which allows you to focus
            on tasks and logs just for that group. Filter changes persist across
            pages, so whatever you set on one page will be applied to other
            pages when you visit them.
          </alc-release-paragraph>
          <alc-release-paragraph>
            We'd love to hear your feedback on how you will use resident groups
            in your facility, and what things you would like to see added.
          </alc-release-paragraph>
          <div class="my-6 grid grid-cols-2 gap-6">
            <alc-release-image
              class="col-span-2 sm:col-span-1"
              src="https://storage.googleapis.com/alcomy-files/release-notes/1.25/resident_groups_page.jpg"
              caption="Resident Groups Page"
            />
            <alc-release-image
              class="col-span-2 sm:col-span-1"
              src="https://storage.googleapis.com/alcomy-files/release-notes/1.25/resident_group_filter.jpg"
              caption="Resident Group Filter"
            />
          </div>
        </alc-release-highlight>

        <alc-release-highlight type="feature" title="Resident Appointments">
          <alc-release-paragraph>
            We've started work on calendaring functionality within Alcomy, and
            today we are releasing a small feature within that greater
            initiative: resident appointments.
          </alc-release-paragraph>

          <alc-release-paragraph>
            You can create a new resident appointment in a couple of place: on
            the <b>Resident Profile > Notebook > Events</b> page via the +
            button, or on the <b>Dashboard Overview</b> page via the + button on
            the new <b>Appointments</b> widget
          </alc-release-paragraph>

          <alc-release-image
            src="https://storage.googleapis.com/alcomy-files/release-notes/1.25/todays_appointments.jpg"
            caption="Today's Appointment Widget"
          />

          <alc-release-paragraph>
            The new widget does pretty much what you would expect: it shows you
            any resident appointments for the selected date range (Today by
            default). In future releases we'll add a dedicated calendar page and
            other functionality.
          </alc-release-paragraph>

          <alc-release-paragraph>
            There's a lot that goes into calendaring. We'd love to hear from you
            about what you'd like to see and the problems you need solved.
          </alc-release-paragraph>
        </alc-release-highlight>

        <h2 class="mb-6 mt-10 text-center font-bold">Additional Goodies</h2>

        <alc-release-item type="feature">
          Added a biography field for general notes about the resident on the
          facesheet.
        </alc-release-item>

        <alc-release-item type="feature">
          Added resident's preferred pharmacy to the facesheet.
        </alc-release-item>

        <alc-release-item type="feature">
          Added residents primary language to the facesheet.
        </alc-release-item>

        <alc-release-item type="improvement">
          Added a new loading indicator for when the application first boots up
          or the webpage is refreshed.
        </alc-release-item>

        <alc-release-item type="improvement">
          Made a number of UI improvements to make things stand out.
        </alc-release-item>

        <alc-release-item type="change">
          Clicking on a resident contact now opens the contact's info in a modal
          rather than an accordion.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Resident documents now appear as expected when a resident is marked
          deceased.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Printed CAR now shows correct times
        </alc-release-item>

        <alc-release-item type="bugfix">
          When omitting a medication task, the omitted amount now auto populates
          with the correct amount.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Deleting a medication was not working for some users. That is now
          fixed.
        </alc-release-item>

        <alc-release-item type="bugfix">
          Adding an inventory item was not working for some users. That is now
          now fixed.
        </alc-release-item>

        <alc-release-footer />
      </alc-release>
    </div>
  </div>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <div class="form-action-buttons">
      <ion-button
        shape="round"
        fill="solid"
        color="dashboard-primary"
        (click)="markRead()"
      >
        <ion-label>Got It!</ion-label>
      </ion-button>
      <ion-button
        shape="round"
        fill="outline"
        color="dashboard-primary"
        *ngIf="trigger === 'automatic'"
        (click)="dismiss()"
      >
        <ion-label>Show Me Later</ion-label>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
