import { facilitiesAdapter } from '$/app/store/facilities/facilities.state';
import { selectRouterState } from '$/app/store/router/router.selectors';
import { UserSelectors } from '$/app/store/user/user.selectors';
import { Facility } from '$/models';
import { GeneralSettingsEnum } from '$shared/facility-settings/constants';
import { applyDefaultsToFacilityFeatureFlags } from '$shared/feature-flags';
import { FeatureFlagId } from '$shared/lookups/feature-flags.lookup';
import { createSelector } from '@ngrx/store';
import { selectCurrentFacilitySettingValueByCode } from '../facility-settings/facility-settings.selectors';
import { facilitiesFeature } from './facilities.reducer';

export const {
  selectFacilitiesState,
  selectEntities,
  selectIds,
  selectLoading,
  selectLoaded,
  selectError
} = facilitiesFeature;

export const { selectAll, selectTotal } = facilitiesAdapter.getSelectors(
  selectFacilitiesState
);

export const selectFacility = createSelector(
  selectEntities,
  selectRouterState,
  (entities, routerState) => {
    return routerState && entities[routerState.params.facilityId];
  }
);

export const selectCurrentFacility = createSelector(
  selectEntities,
  UserSelectors.selectFacilityId,
  (entities, facilityId): Facility => {
    if (!facilityId || !entities) {
      return undefined;
    }

    return entities[facilityId];
  }
);

export const selectFacilityState = createSelector(
  selectCurrentFacility,
  (facility) => facility?.state
);

export const selectFeatureFlags = createSelector(
  selectCurrentFacility,
  selectCurrentFacilitySettingValueByCode(GeneralSettingsEnum.FEATURE_FLAGS),
  (facility, facilityFeatureFlags) => {
    return applyDefaultsToFacilityFeatureFlags(facilityFeatureFlags, facility);
  }
);

export const selectFeatureFlagEnabled = (flag: FeatureFlagId) =>
  createSelector(selectFeatureFlags, (flags) => flags[flag]);
