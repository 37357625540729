import { Params } from '$/models';
import { MedicationTaskStateId } from '$shared/lookups/medication-task-states.lookup';
import { MedicationTaskSubmission } from '$shared/medication-tasks';
import { boolDictionaryToArray } from '$shared/utils/bool-dictionary';
import { createActionGroup, props } from '@ngrx/store';
import { RoutineMarListFilters } from './routine-mar-list.vmodel';

interface GetQueryOptions {
  medicationBatchId: string;
  filters: RoutineMarListFilters;
  params?: Params;
  $skip?: number;
}

const defaultQuery = {
  $limit: 100,
  $sort: {
    scheduledFor: -1
  },
  $eager: {
    amounts: true,
    mars: {
      facilityUser: true,
      omittedMedication: {
        amounts: true
      }
    },
    schedule: true,
    medication: {
      $modify: 'forMarList'
    },
    behaviorLog: {
      behavior: true
    },
    bloodPressureLog: true,
    bloodSugarLog: true,
    bodyCheckLog: {
      observations: true
    },
    bmLog: true,
    foodLog: true,
    moodLog: true,
    oxygenLog: true,
    positionLog: true,
    seizureLog: {
      symptoms: {
        symptom: true
      }
    },
    showerLog: true,
    sleepLog: true,
    temperatureLog: true,
    urineLog: true,
    waterLog: true,
    weightLog: true
  }
};

export const RoutineMarListPageActions = createActionGroup({
  source: 'Routine Mar List Page',
  events: {
    'Load Medication Tasks': ({
      medicationBatchId,
      filters,
      $skip,
      params
    }: GetQueryOptions) => {
      const { query, ...rest } = params || {};

      const states: MedicationTaskStateId[] = boolDictionaryToArray(
        filters.state
      );

      if (filters.state.omit) {
        states.push('popOmitted', 'passOmitted');
      }

      return {
        params: {
          query: {
            ...defaultQuery,
            medicationBatchId,
            currentState: {
              $in: filters.missedOnly ? ['created', 'pop'] : states
            },
            scheduledFor: {
              $gte: filters.dateRange.startDate.toISO(),
              $lte: filters.dateRange.endDate.toISO()
            },
            $skip: $skip || 0
          },
          ...rest
        }
      };
    },

    'Get Medication Tasks': ({
      medicationBatchId,
      filters,
      $skip,
      params
    }: GetQueryOptions) => {
      const { query, ...rest } = params || {};

      const states: MedicationTaskStateId[] = boolDictionaryToArray(
        filters.state
      );

      if (filters.state.omit) {
        states.push('popOmitted', 'passOmitted');
      }

      return {
        params: {
          query: {
            ...defaultQuery,
            medicationBatchId,
            currentState: {
              $in: states
            },
            scheduledFor: {
              $gte: filters.dateRange.startDate.toISO(),
              $lte: filters.dateRange.endDate.toISO()
            },
            $skip: $skip || 0
          },
          ...rest
        }
      };
    },

    'Change Current State': (
      id: string,
      changes: MedicationTaskSubmission,
      params: Params = { query: {} }
    ) => ({ id, changes, params }),

    'Get Medication Inventory Items': (params: Params) => ({ params }),

    'Delete Activity Log': props<{ id: string; params?: Params }>(),
    'Delete Behavior Log': props<{ id: string; params?: Params }>(),
    'Delete Blood Pressure Log': props<{ id: string; params?: Params }>(),
    'Delete Blood Sugar Log': props<{ id: string; params?: Params }>(),
    'Delete Bowel Movement Log': props<{ id: string; params?: Params }>(),
    'Delete Body Check Log': props<{ id: string; params?: Params }>(),
    'Delete Food Log': props<{ id: string; params?: Params }>(),
    'Delete Mood Log': props<{ id: string; params?: Params }>(),
    'Delete Oxygen Log': props<{ id: string; params?: Params }>(),
    'Delete Position Log': props<{ id: string; params?: Params }>(),
    'Delete Respiration Log': props<{ id: string; params?: Params }>(),
    'Delete Seizure Log': props<{ id: string; params?: Params }>(),
    'Delete Shower Log': props<{ id: string; params?: Params }>(),
    'Delete Sleep Log': props<{ id: string; params?: Params }>(),
    'Delete Temperature Log': props<{ id: string; params?: Params }>(),
    'Delete Urine Log': props<{ id: string; params?: Params }>(),
    'Delete Vital Log': props<{ id: string; params?: Params }>(),
    'Delete Water Log': props<{ id: string; params?: Params }>(),
    'Delete Weight Log': props<{ id: string; params?: Params }>()
  }
});
