import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { ResidentLogTypesApiService } from '$/app/services';
import { Paginated, ResidentLogType } from '$/models';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';

import { ResidentLogTypesApiActions } from './actions';
import { VitalResidentSettingsActions } from '$/app/pages/notebook/residents/vital-resident-settings-modal/vital-resident-settings.modal.actions';
import { LogVisibilityModalActions } from '$/app/pages/notebook/residents/log-visibility-modal/log-visibility-modal-actions';

@Injectable()
export class ResidentLogTypesEffects {
  private readonly actions$ = inject(Actions);
  private readonly residentLogTypesService = inject(ResidentLogTypesApiService);

  getResidentLogTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LogsPageActions.getResidentLogTypes),
      switchMap((action) => {
        return this.residentLogTypesService.getAll(action.params).pipe(
          map((residentLogTypes: Paginated<ResidentLogType>) => {
            return ResidentLogTypesApiActions.getResidentLogTypesSuccess({
              residentLogTypes
            });
          }),
          catchError((error) => {
            return of(
              ResidentLogTypesApiActions.getResidentLogTypesFail({ error })
            );
          })
        );
      })
    );
  });

  createResidentLogType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LogVisibilityModalActions.turnOnLog),
      exhaustMap((action) => {
        return this.residentLogTypesService.create(action.residentLogType).pipe(
          map((residentLogType: ResidentLogType) => {
            return ResidentLogTypesApiActions.createResidentLogTypeSuccess({
              residentLogType
            });
          }),
          catchError((error) => {
            return of(
              ResidentLogTypesApiActions.createResidentLogTypeFail({
                error
              })
            );
          })
        );
      })
    );
  });

  updateLogType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        LogVisibilityModalActions.updateLogSettings,
        VitalResidentSettingsActions.updateResidentLogType
      ),
      switchMap((action) =>
        this.residentLogTypesService
          .patch(action.id, action.changes, action.params)
          .pipe(
            map((data: ResidentLogType) =>
              ResidentLogTypesApiActions.updateResidentLogTypeSuccess({
                residentLogType: data
              })
            ),
            catchError((error) =>
              of(
                ResidentLogTypesApiActions.updateResidentLogTypeFail({ error })
              )
            )
          )
      )
    );
  });

  deleteResidentLogType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LogVisibilityModalActions.turnOffLog),
      exhaustMap((action) => {
        return this.residentLogTypesService
          .delete(action.id, action.params)
          .pipe(
            map((residentLogType) => {
              return ResidentLogTypesApiActions.deleteResidentLogTypeSuccess({
                id: residentLogType.id
              });
            }),
            catchError((error) => {
              return of(
                ResidentLogTypesApiActions.deleteResidentLogTypeFail({
                  error
                })
              );
            })
          );
      })
    );
  });
}
