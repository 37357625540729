import { OverlayService } from '$/app/services';
import { expansionAnimation } from '$/app/shared/animations/animations';
import { AlcDiffComponent } from '$/app/shared/components/diff/diff.component';
import { SharedModule } from '$/app/shared/shared.module';
import { AlcomyValidators } from '$/app/shared/validators';
import { validateForm } from '$/app/utils';
import { AlcomyTheme } from '$/models';
import { Logger } from '$shared/logger';
import { lookups } from '$shared/lookups';
import { IMedicalProfessionalPhone } from '$shared/services/medical-professional-phone.schema';
import {
  IMedicalProfessional,
  IMedicalProfessionalWithPhones
} from '$shared/services/medical-professional.schema';
import { IProposedMedicalProfessionalChange } from '$shared/services/proposed-changes.schema';
import { Component, Input, OnInit, inject } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { AlertController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { trim } from 'lodash';
import { MedicalProfessionalFormActions } from './medical-professional-form.actions';
import { FacilitiesSelectors } from '$/app/store';

@Component({
  selector: 'alc-medical-professional-form',
  imports: [SharedModule, AlcDiffComponent],
  templateUrl: './medical-professional-form.modal.html',
  animations: [expansionAnimation]
})
export class AlcMedicalProfessionalFormModal implements OnInit {
  private readonly fb = inject(UntypedFormBuilder);
  private readonly overlay = inject(OverlayService);
  private readonly alert = inject(AlertController);
  private readonly store = inject(Store);

  protected form: UntypedFormGroup;

  @Input({ required: true }) theme: AlcomyTheme;
  @Input() residentId?: string;
  @Input() medicalProfessional: Partial<IMedicalProfessionalWithPhones>;
  @Input() proposedChange: IProposedMedicalProfessionalChange;

  protected readonly facility = this.store.selectSignal(
    FacilitiesSelectors.selectCurrentFacility
  );

  protected medicalProfessionalTypes() {
    const medicalProfessionalTypes = this.lookups.medicalProfessionalTypes;
    // if facility is not in California and not an ARF remove CSC and Regional Center from medical professional types
    if (this.facility().state !== 'US-CA' || this.facility().type !== 'arf') {
      return medicalProfessionalTypes.filter(
        (types) => types.id !== 'csc' && types.id !== 'rc'
      );
    } else {
      return medicalProfessionalTypes;
    }
  }

  protected readonly lookups = lookups;

  protected get formPhones() {
    return this.form.get('phones') as UntypedFormArray;
  }

  ngOnInit() {
    this.createForm();
  }

  protected async close() {
    await this.overlay.dismissModal();
  }

  protected addPhone(ev: Event) {
    this.formPhones.push(this.newPhone());
    setTimeout(() => {
      ev.target['scrollIntoView']({
        behavior: 'smooth',
        block: 'center'
      });
    }, 100);
  }

  protected removePhone(index: number) {
    this.formPhones.removeAt(index);
  }

  protected submit() {
    try {
      const medicalProfessional = validateForm<IMedicalProfessional>(this.form);

      if (!trim(medicalProfessional.email)) {
        medicalProfessional.email = null;
      }

      const $actions = this.proposedChange
        ? {
            $actions: [
              {
                updateProposedChange: {
                  proposedChangeId: this.proposedChange.id
                }
              }
            ]
          }
        : {};

      if (!this.medicalProfessional) {
        this.store.dispatch(
          MedicalProfessionalFormActions.createMedicalProfessional({
            medicalProfessional: {
              ...medicalProfessional,
              residentId: this.residentId
            },
            params: {
              query: {
                ...$actions
              },
              loading: 'Creating medical professional',
              toast: 'Medical professional created successfully!',
              onSuccess: () => this.close()
            }
          })
        );
      } else {
        this.store.dispatch(
          MedicalProfessionalFormActions.updateMedicalProfessional({
            id: this.medicalProfessional.id,
            changes: medicalProfessional,
            params: {
              query: {
                ...$actions
              },
              loading: 'Updating medical professional',
              toast: 'Medical professional updated successfully!',
              onSuccess: () => this.close()
            }
          })
        );
      }
    } catch (error) {
      Logger.error('Error validating medical professional form', { error });

      this.overlay.showAlert(
        'Please check the highlighted fields and make sure they are filled in properly.',
        'Incorrect values'
      );
    }
  }

  protected rejectChanges() {
    this.overlay.confirm(
      'The medical professional will not be updated with what the pharmacy has on file.',
      'Reject proposed changes',
      () => {
        this.store.dispatch(
          MedicalProfessionalFormActions.rejectProposedChange({
            id: this.proposedChange.id,
            changes: {
              status: 'rejected',
              rejectionReason: 'Kept original values'
            },
            params: {
              loading: 'Rejecting proposed change',
              toast: 'Proposed change rejected successfully!',
              onSuccess: () => this.close()
            }
          })
        );
      }
    );
  }

  private createForm() {
    let phones = this.medicalProfessional?.phones || [];

    if (!phones.length && this.proposedChange?.changes.phones?.length) {
      phones = this.proposedChange.changes.phones;
    }

    this.form = this.fb.group({
      typeId: [
        this.proposedChange?.changes.typeId ||
          this.medicalProfessional?.typeId ||
          null,
        Validators.required
      ],
      firstName: [
        this.proposedChange?.changes.firstName ||
          this.medicalProfessional?.firstName ||
          null
      ],
      lastName: [
        this.proposedChange?.changes.lastName ||
          this.medicalProfessional?.lastName ||
          null,
        Validators.required
      ],
      email: [
        this.proposedChange?.changes.email ||
          this.medicalProfessional?.email ||
          null,
        AlcomyValidators.email
      ],
      specialty: [
        this.proposedChange?.changes.specialty ||
          this.medicalProfessional?.specialty ||
          null
      ],
      address1: [
        this.proposedChange?.changes.address1 ||
          this.medicalProfessional?.address1 ||
          null
      ],
      address2: [
        this.proposedChange?.changes.address2 ||
          this.medicalProfessional?.address2 ||
          null
      ],
      city: [
        this.proposedChange?.changes.city ||
          this.medicalProfessional?.city ||
          null
      ],
      state: [
        this.proposedChange?.changes.state ||
          this.medicalProfessional?.state ||
          null
      ],
      postalCode: [
        this.proposedChange?.changes.postalCode ||
          this.medicalProfessional?.postalCode ||
          null
      ],
      country: [
        {
          value:
            this.proposedChange?.changes.country ||
            this.medicalProfessional?.country ||
            'US',
          disabled: true
        }
      ],
      phones: this.fb.array(phones.map((phone) => this.newPhone(phone))),
      notes: this.medicalProfessional?.notes || null,
      npi: [
        {
          value:
            this.proposedChange?.changes.npi ||
            this.medicalProfessional?.npi ||
            null,
          disabled:
            this.proposedChange?.changes.npi || this.medicalProfessional?.npi
        }
      ]
    });
  }

  private newPhone(phone?: IMedicalProfessionalPhone) {
    return this.fb.group({
      label: [phone?.label ?? null],
      number: [
        phone?.number ?? null,
        [Validators.required, Validators.minLength(10)]
      ],
      type: [phone?.type ?? 'mobile', Validators.required],
      isPrimary: [phone?.isPrimary ?? false]
    });
  }
}
