import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { IResident } from './residents.interface';

export function calculateAge(resident?: IResident): number | undefined | null {
  if (isNil(resident?.birthDate)) {
    return resident?.birthDate;
  }

  const until =
    resident.deceasedAt ?? resident.dischargedAt ?? DateTime.now().toISO();
  const birthDate = DateTime.fromISO(resident.birthDate);
  return birthDate.diff(DateTime.fromISO(until), 'years').negate().rescale()
    .years;
}

export function getAmbulatoryStatus(resident: IResident): {
  isAmbulatory: boolean;
  ambulatoryStatus: 'Ambulatory' | 'Non-ambulatory';
} {
  const isAmbulatory =
    resident.mobilityStatus === 'ambulatory' && resident.canFollowInstructions;
  const ambulatoryStatus = isAmbulatory ? 'Ambulatory' : 'Non-ambulatory';

  return {
    isAmbulatory,
    ambulatoryStatus
  };
}
