import { Table } from '$/models/data/base';

export class Role extends Table {
  orgId?: string;
  facilityId?: string;
  name?: string;
  description?: string;
  geofence?: boolean;
  restrictToIp?: boolean;
}
