import { IRespirationLog } from '$shared/services/respiration-log.schema';
import { Paginated } from '$shared/types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const RespirationLogsApiActions = createActionGroup({
  source: 'Respiration Logs Api',
  events: {
    'Load Respiration Logs Success': props<{
      respirationLogs: Paginated<IRespirationLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Get Respiration Logs Success': props<{
      respirationLogs: Paginated<IRespirationLog>;
      metadata: { pageQueryType: string };
    }>(),
    'Create Respiration Log Success': props<{
      respirationLog: IRespirationLog;
    }>(),
    'Update Respiration Log Success': props<{
      respirationLog: IRespirationLog;
    }>(),
    'Delete Respiration Log Success': props<{ id: string }>(),

    'Load Respiration Logs Fail': props<{ error: Error }>(),
    'Get Respiration Logs Fail': props<{ error: Error }>(),
    'Create Respiration Log Fail': props<{ error: Error }>(),
    'Update Respiration Log Fail': props<{ error: Error }>(),
    'Delete Respiration Log Fail': props<{ error: Error }>()
  }
});

export const RespirationLogsWsActions = createActionGroup({
  source: 'Respiration Logs Ws',
  events: {
    'Respiration Logs Created': props<{
      respirationLog: IRespirationLog;
    }>(),
    'Respiration Logs Patched': props<{
      respirationLog: IRespirationLog;
    }>(),
    'Respiration Logs Removed': props<{ id: string }>()
  }
});

export const RespirationLogsGeneralActions = createActionGroup({
  source: 'Respiration Logs General',
  events: {
    'Clear Respiration Logs': emptyProps(),
    'Add Respiration Logs': props<{
      respirationLogs: IRespirationLog[];
    }>()
  }
});
