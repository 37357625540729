<div
  class="flex items-center justify-start rounded-2xl border border-solid p-4 sm:px-6"
  [ngClass]="{
    default: displayColor() === 'default',
    info: displayColor() === 'primary',
    warning: displayColor() === 'warning',
    error: displayColor() === 'danger',
    success: displayColor() === 'success'
  }"
>
  @if (displayIcon() && displayIcon() !== 'none') {
    <div class="mr-4 flex items-start">
      <ion-icon class="text-2xl" [src]="displayIcon()" />
    </div>
  }

  <div
    [ngClass]="{
      info: displayColor() === 'primary',
      warning: displayColor() === 'warning',
      error: displayColor() === 'danger',
      success: displayColor() === 'success'
    }"
  >
    <ng-content />
  </div>
</div>
