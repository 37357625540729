import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { sortByDesc } from '$/app/utils/sorting/sort-by-desc';
import { IPaginationInfo } from '$/models';
import { IVitalLog } from '$shared/services/vital-log.schema';

export interface VitalLogPageQueries {
  all: IPaginationInfo;
}

export const vitalLogsAdapter = createEntityAdapter<IVitalLog>({
  sortComparer: sortByDesc('recordAt')
});

export interface State extends EntityState<IVitalLog> {
  loading: boolean;
  loaded: boolean;
  error;
}

export const initialState: State = vitalLogsAdapter.getInitialState({
  pagination: {
    all: {
      total: 0,
      skip: 0
    }
  },
  loading: false,
  loaded: false,
  error: null
});
