import { identity, isArray, isEmpty, isFunction } from 'lodash';
import { Logger } from './logger';

interface IOptions {
  /**
   * @property {number} concurrency
   * - The maximum number of tasks (promises) that can run concurrently.
   * - Limits the number of active asynchronous operations at any given time.
   * - Helps manage resource usage, preventing overwhelming the system or external services.
   * - Defaults to a reasonable value (e.g., 10) if not explicitly provided.
   * - Example Use Case: When processing API calls, you might set this to the API's rate limit.
   */
  concurrency?: number;
  /**
   * @property {number} queueSize
   * - The maximum number of pending tasks that can be queued in memory.
   * - Ensures that memory usage remains manageable by limiting how many tasks are kept in memory
   *   before being executed.
   * - If the queue reaches this limit, the function waits for active tasks to complete before
   *   adding more to the queue.
   * - Defaults to `1000` if not explicitly provided.
   * - Can be set to `Infinity` to allow an unlimited number of tasks to be queued. Useful if you want
   *   to implement your own queue management externally.
   * - Example Use Case: For large datasets, setting a queueSize helps prevent memory exhaustion
   *   by processing tasks in smaller, controlled batches.
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   */
  queueSize?: number;
}

const DEFAULT_OPTIONS: Required<IOptions> = {
  concurrency: 10,
  queueSize: 1000
};

type AsyncFunc = () => Promise<any>;

type UnwrapPromise<T> = T extends Promise<infer U> ? U : T;

type PromisifyObjectResults<T extends Record<string, AsyncFunc>> = {
  [K in keyof T]: UnwrapPromise<ReturnType<T[K]>>;
};

export async function parallelMap<T, U = T>(
  items: T[],
  processor?: (item: T, index: number) => Promise<U>,
  options?: IOptions
): Promise<U[]>;

export async function parallelMap<T, U = T>(
  items: Record<string, T>,
  processor: (item: T, key: string) => Promise<U>,
  options?: IOptions
): Promise<Record<string, U>>;

export async function parallelMap<T extends Record<string, AsyncFunc>>(
  items: T,
  options?: IOptions
): Promise<PromisifyObjectResults<T>>;

export async function parallelMap<T>(...args: any[]): Promise<any> {
  const {
    items,
    processor,
    options: { concurrency, queueSize }
  } = decodeArgs<T>(args);

  const isArrayInput = Array.isArray(items);

  // Convert items to entries (index-item pairs for arrays or key-value pairs for objects)
  const entries = isArrayInput
    ? items.map((item, index) => [index, item] as [number, T])
    : // eslint-disable-next-line
      (Object.entries(items) as [string, T][]);

  // Tracks active tasks and their promises
  const activeTasks = new Set<Promise<void>>();

  const results: any = isArrayInput ? [] : {};
  // const promisesByItemKey: Record<string, Promise<string | number>> = {};

  const processItem = async ([key, item]:
    | [number, T]
    | [string, T]): Promise<void> => {
    const result = await processor(item, key); // Call processor
    results[key] = result; // Store result
  };

  for (const entry of entries) {
    const task: Promise<any> = processItem(entry).then(() =>
      activeTasks.delete(task)
    );

    activeTasks.add(task);

    // Wait if active tasks exceed queueSize
    if (activeTasks.size >= queueSize) {
      await Promise.race(activeTasks); // Wait for at least one task to complete
    }

    // Wait if concurrency limit is reached
    if (activeTasks.size >= concurrency) {
      await Promise.race(activeTasks); // Wait for one task to finish
    }
  }

  // Wait for all remaining tasks to complete
  await Promise.all(activeTasks);

  return results;
}

function decodeArgs<T>(args: any[]): {
  items: T[];
  processor: (...args: any[]) => any;
  options: Required<IOptions>;
} {
  Logger.assert(args.length >= 1 && args.length <= 3, 'Invalid arguments', {
    args
  });

  const items = args[0];

  if (isEmpty(items)) {
    return { items: [], processor: identity, options: DEFAULT_OPTIONS };
  }

  if (isArray(items)) {
    switch (args.length) {
      case 1:
        // parallelMap(items: T[])
        return { items, processor: identity, options: DEFAULT_OPTIONS };

      case 2:
        // parallelMap(
        //   items: T[],
        //   processor: (item: T) => Promise<U>)
        return { items, processor: args[1], options: DEFAULT_OPTIONS };

      case 3:
        // parallelMap(
        //   items: T[],
        //   processor: (item: T) => Promise<U>,
        //   options: IOptions)
        return {
          items,
          processor: args[1],
          options: getOptions(args[2])
        };
    }
  }

  // parallelMap(
  //   items: Record<string, T>,
  //   processor: (item: T) => Promise<U>)
  //   options?: IOptions)
  if (isFunction(args[1])) {
    return {
      items,
      processor: args[1],
      options: getOptions(args[2])
    };
  }

  // parallelMap(
  //   items: Record<string, AsyncFunc>,
  //   options?: IOptions)
  return {
    items,
    processor: (item: () => any) => item(),
    options: getOptions(args[2])
  };
}

function getOptions({
  concurrency = DEFAULT_OPTIONS.concurrency,
  queueSize = DEFAULT_OPTIONS.queueSize
}: IOptions | undefined = DEFAULT_OPTIONS): Required<IOptions> {
  return { concurrency, queueSize };
}
