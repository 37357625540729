import { ResidentGroup } from '$/models/data/entities/residents/resident-group.model';
import { MaybeArray } from '$shared/types/utility-types';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ResidentGroupsApiActions = createActionGroup({
  source: 'Resident Group API',
  events: {
    'Load Resident Groups Success': props<{
      residentGroups: ResidentGroup[];
    }>(),
    'Get Resident Groups Success': props<{
      residentGroups: ResidentGroup[];
    }>(),
    'Fetch Resident Group Success': props<{
      residentGroup: ResidentGroup;
    }>(),
    'Create Resident Group Success': props<{
      residentGroup: MaybeArray<ResidentGroup>;
    }>(),
    'Update Resident Group Success': props<{
      residentGroup: ResidentGroup;
    }>(),
    'Delete Resident Group Success': props<{ id: string }>(),

    'Load Resident Groups Fail': props<{ error: Error }>(),
    'Get Resident Groups Fail': props<{ error: Error }>(),
    'Fetch Resident Group Fail': props<{ error: Error }>(),
    'Create Resident Group Fail': props<{ error: Error }>(),
    'Update Resident Group Fail': props<{ error: Error }>(),
    'Delete Resident Group Fail': props<{ error: Error }>()
  }
});

export const ResidentGroupsWsActions = createActionGroup({
  source: 'Resident Group WS',
  events: {
    'Resident Groups Created': props<{
      residentGroup: ResidentGroup;
    }>(),
    'Resident Groups Patched': props<{
      residentGroup: ResidentGroup;
    }>(),
    'Resident Groups Removed': props<{ id: string }>()
  }
});

export const ResidentGroupsGeneralActions = createActionGroup({
  source: 'Resident Group General',
  events: {
    'Clear Resident Groups': emptyProps(),
    'Add Resident Groups': props<{ residentGroups: ResidentGroup[] }>()
  }
});
