import { ResidentGroupFormActions } from '$/app/pages/residents/resident-group-form/resident-group-form.actions';
import { ResidentGroupsListPageActions } from '$/app/pages/residents/resident-groups/resident-groups-list.actions';
import { EffectHelpersService } from '$/app/services';
import { ResidentGroupsApiService } from '$/app/store/resident-groups/resident-groups.service';
import { ApiData } from '$/app/utils';
import { ResidentGroup } from '$/models/data/entities/residents/resident-group.model';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ResidentGroupsApiActions } from './resident-groups.actions';

@Injectable()
export class ResidentGroupsEffects {
  private readonly actions$ = inject(Actions);
  private readonly residentGroupsService = inject(ResidentGroupsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadResidentGroups$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentGroupsListPageActions.loadResidentGroups),
      this.effectHelpers.apiRequest({
        description: 'Load Resident Groups',
        onRequest: (action) => this.residentGroupsService.getAll(action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'residentGroups',
            response,
            ResidentGroupsApiActions.loadResidentGroupsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) =>
          ResidentGroupsApiActions.loadResidentGroupsFail({ error })
      })
    );
  });

  createResidentGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentGroupFormActions.createResidentGroup),
      this.effectHelpers.apiRequest({
        description: 'Create Resident Group',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.residentGroupsService.create(
            action.residentGroup,
            action.params
          );
        },
        onSuccess: (residentGroup) =>
          ResidentGroupsApiActions.createResidentGroupSuccess({
            residentGroup
          }),
        onError: (error) =>
          ResidentGroupsApiActions.createResidentGroupFail({ error })
      })
    );
  });

  updateResidentGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentGroupFormActions.updateResidentGroup),
      this.effectHelpers.apiRequest({
        description: 'Update Resident Group',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.residentGroupsService.patch(
            action.id,
            action.changes,
            action.params
          );
        },
        onSuccess: (residentGroup) =>
          ResidentGroupsApiActions.updateResidentGroupSuccess({
            residentGroup
          }),
        onError: (error) =>
          ResidentGroupsApiActions.updateResidentGroupFail({ error })
      })
    );
  });

  deleteResidentGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentGroupsListPageActions.deleteResidentGroup),
      this.effectHelpers.apiRequest({
        description: 'Delete Resident Group',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.residentGroupsService.delete(action.id, action.params);
        },
        onSuccess: (residentGroup: ResidentGroup) =>
          ResidentGroupsApiActions.deleteResidentGroupSuccess({
            id: residentGroup.id
          }),
        onError: (error) =>
          ResidentGroupsApiActions.deleteResidentGroupFail({ error })
      })
    );
  });
}
