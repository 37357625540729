import { FeathersService } from '$/app/services/feathers.service';
import { FilesWsActions } from '$/app/store/files';
import { ISignedFile } from '$shared/files/signed-file.type';
import { Logger } from '$shared/logger';
import { IFile } from '$shared/services/file.schema';
import { Cache, toLuxon } from '$shared/utils';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { FileSharer } from '@byteowls/capacitor-filesharer';
import {
  FileOpener,
  FileOpenerOptions
} from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Store } from '@ngrx/store';
import write_blob from 'capacitor-blob-writer';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import path from 'path-browserify';
import { lastValueFrom } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AppInfo } from '../utils';
import { AbstractApiService } from './abstract-api-service.service';

const urlCache = new Cache<string, string>();
const fileCache = new Cache<string, ISignedFile>();
const urlPromiseCache = new Cache<string, Promise<ISignedFile>>();
const filePromiseCache = new Cache<string, Promise<ISignedFile>>();

@Injectable({ providedIn: 'root' })
export class FilesApiService extends AbstractApiService<IFile | ISignedFile> {
  private readonly http = inject(HttpClient);

  downloadProgress = 0;
  downloadingUrls: string[] = [];

  constructor(feathers: FeathersService, store: Store) {
    super('files', feathers, store, {
      entityName: 'file',
      created: FilesWsActions.fileCreated,
      patched: FilesWsActions.filePatched,
      removed: FilesWsActions.fileRemoved
    });
  }

  async getUrl(
    id: string,
    options: { skipCache: boolean } = { skipCache: false }
  ): Promise<string | undefined> {
    if (!id) {
      return undefined;
    }

    if (!options.skipCache) {
      const url = urlCache.get(id);

      if (url) {
        return url;
      }
    }

    let promise = urlPromiseCache.get(id);

    if (!promise) {
      promise = this.service.get(id, {
        query: { $actions: [{ getUrl: true }] }
      }) as Promise<ISignedFile>;

      urlPromiseCache.set(id, promise, DateTime.now().plus({ minute: 1 }));
    }

    try {
      const result = await promise;

      urlCache.set(id, result.url, toLuxon(result.expiresAt));
      return result.url;
    } finally {
      urlPromiseCache.delete(id);
    }
  }

  // Use this when you need the file metadata as well as the signed URL. e.g.
  // you need the content type in order to display the file in the right
  // component
  async getFileWithSignedUrl(
    id: string,
    options: { skipCache: boolean } = { skipCache: false }
  ): Promise<ISignedFile | undefined> {
    if (!id) {
      return undefined;
    }

    if (!options.skipCache) {
      const file = fileCache.get(id);

      if (file) {
        return file;
      }
    }

    let promise = filePromiseCache.get(id);

    if (!promise) {
      promise = this.service.get(id, {
        query: {
          $actions: [
            {
              signFile: {
                skipCache: options.skipCache
              }
            }
          ]
        }
      }) as Promise<ISignedFile>;

      filePromiseCache.set(id, promise, DateTime.now().plus({ minute: 1 }));
    }

    try {
      const result = await promise;

      const expiresAt = toLuxon(result.expiresAt);

      fileCache.set(id, result, expiresAt);
      urlCache.set(id, result.url, expiresAt);
      return result;
    } finally {
      filePromiseCache.delete(id);
    }
  }

  async download(fileOrId: string | ISignedFile): Promise<void> {
    if (!fileOrId) {
      Logger.warn('No file or fileId provided to download()', {
        fileOrId
      });

      return;
    }

    this.downloadProgress = 0;

    let file: ISignedFile;

    if (typeof fileOrId === 'string') {
      file = await this.getFileWithSignedUrl(fileOrId);

      if (!file) {
        Logger.warn('No signed file was found', { fileOrId });
        return;
      }
    } else {
      file = fileOrId;
    }

    if (!file.url) {
      return;
    }

    return await lastValueFrom(
      this.http
        .get(file.url, {
          responseType: 'blob',
          reportProgress: true,
          observe: 'events'
        })
        .pipe(
          tap((event) => {
            if (event.type === HttpEventType.DownloadProgress) {
              this.downloadProgress = Math.round(
                (100 * event.loaded) / event.total
              );

              Logger.info(`Download progress ${this.downloadProgress}`);
            }
          }),
          mergeMap(async (event) => {
            if (event.type === HttpEventType.Response) {
              this.downloadProgress = 100;

              const filename = path.basename(file.path);

              Logger.info(`Filename: ${filename}`);

              const blob = event.body;

              if (AppInfo.deviceInfo.platform === 'web') {
                saveAs(blob, filename);
              } else {
                const filePath = await this._getFilePath(filename, blob);

                Logger.info(`Downloaded file to ${filePath}`);

                await this._openFileWithType(filePath, file.contentType);

                this.downloadProgress = 100;

                this.downloadingUrls = this.downloadingUrls.filter(
                  (dUrl) => dUrl !== file.url
                );
              }
            }
          })
        )
    );
  }

  // 4 Method to retrieve the fiel we've saved
  private async _getFilePath(filename: string, blob: Blob): Promise<string> {
    try {
      // 5. Save the file locally
      const uri = await this._saveBlobFile(blob, filename);

      Logger.info(`File saved to ${uri}`);

      return uri;
    } catch (error) {
      Logger.error(`Download error`, { error });
      // 6. In case the file did already exists -> we retrieve it
      await Filesystem.getUri({
        path: filename,
        directory: Directory.Cache
      })
        .then((savedFile) => {
          Logger.info(`File already saved ${savedFile.uri}`);
          return savedFile.uri;
        })
        .catch((error) => {
          Logger.error(error.message, { error });
          throw new Error('Cannot save/open the file');
        });
    }
  }

  // 5. Save the file locally
  private async _saveBlobFile(blob: Blob, filename: string): Promise<string> {
    // const base64 = await this.blobToBase64(blob);

    // const result = await Filesystem.writeFile({
    //   path: filename,
    //   data: base64
    //   directory: Directory.Cache
    // });

    // return result.uri;

    const result = await write_blob({
      path: filename,
      directory: Directory.Data,
      fast_mode: true,
      blob
    });

    Logger.info(`File saved`, { result });

    return result;
  }

  // 7. Open the file
  private async _openFileWithType(
    filePath: string,
    fileType: string
  ): Promise<void> {
    const fileOpenerOptions: FileOpenerOptions = {
      filePath,
      contentType: fileType
    };

    await FileOpener.open(fileOpenerOptions)
      .then(() => {
        Logger.info('File opened');
      })
      .catch((error) => {
        Logger.error('File open error', { error });
      });
  }

  //  Bonus: Localhost:4200. A little more code, to make it work even while testing on your laptop ;)
  public async openFile(file: ISignedFile) {
    if (!file?.url) return;

    Logger.info('Opening file', file);

    try {
      await this.download(file);
      // const result = await Filesystem.downloadFile({
      //   path: path.basename(file.path),
      //   directory: Directory.Documents,
      //   url: file.url,
      //   progress: true
      // });
    } catch (error) {
      Logger.error('openFile error', { error });
    }
  }

  async share(file: ISignedFile) {
    const response = await fetch(file.url);

    await FileSharer.share({
      filename: file.path.split('/').pop(),
      contentType: file.contentType,
      base64Data: await this.blobToBase64(await response.blob())
    });
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
}
