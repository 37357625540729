import { keyBy } from 'lodash';
import { StateId } from '../lookups/states.lookup';
import { IFormConfig } from './types';

export const formConfigs: readonly IFormConfig[] = [
  {
    digitalFormId: 'US-CA/LIC 601 - Identification and Emergency Information',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'id-and-emergency',
    signers: [
      [
        {
          role: 'resident',
          pdfSignatureField: 'Text1',
          signatureDateField: 'Text4'
        },
        {
          role: 'residentRep',
          pdfSignatureField: 'Text2',
          signatureDateField: 'Text4'
        }
      ]
    ]
  },
  {
    digitalFormId: 'US-CA/LIC 602A - Physicians Report',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'physicians-report',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: 'resident/rep signature',
        signatureDateField: 'date 1 pg 1'
      },
      {
        role: 'physician',
        pdfSignatureField: 'physicians signature',
        signatureDateField: 'date signed'
      }
    ]
  },
  {
    digitalFormId: 'US-CA/LIC 603 - Preplacement Appraisal Information',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'preplacement-appraisal-information',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: 'Signature',
        signatureDateField: 'Pg2DateCompleted'
      },
      {
        role: 'facilityRep',
        pdfSignatureField: 'Signature2',
        signatureDateField: 'Pg2DateCompleted2'
      }
    ]
  },
  {
    digitalFormId:
      'US-CA/LIC 605A - Release of Client Resident Medical Information',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'release-of-medical-information',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: 'client or rep signature',
        signatureDateField: 'date singed'
      }
    ]
  },
  {
    digitalFormId:
      'US-CA/LIC 613 - Personal Rights - Adult Community Care Facilities',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'personal-rights',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: 'representative signature 1',
        signatureDateField: 'date 1'
      }
    ]
  },
  {
    digitalFormId:
      'US-CA/LIC 621 - Client Resident Personal Property and Valuables',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'personal-properties-and-valuables',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: null
      }
    ]
  },
  {
    digitalFormId: 'US-CA/LIC 624 - Unusual Incident Injury Report',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'incident-report',
    signers: []
  },
  {
    digitalFormId: 'US-CA/LIC 624A - Death Report',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'death-report',
    signers: []
  },
  {
    digitalFormId: 'US-CA/LIC 625 - Appraisal - Needs and Services Plan',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'appraisal-needs-and-services-plan',
    signers: [
      [
        {
          role: 'residentRep',
          pdfSignatureField: 'LIC 625 44',
          signatureDateField: 'LIC 625 45'
        },
        {
          role: 'resident',
          pdfSignatureField: 'LIC 625 46',
          signatureDateField: 'LIC 625 47'
        }
      ],
      {
        role: 'facilityRep',
        pdfSignatureField: 'LIC 625 42',
        signatureDateField: 'LIC 625 43'
      }
    ]
  },
  {
    digitalFormId: 'US-CA/LIC 627C - Consent for Emergency Medical Treatment',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'medical-consent',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: 'client-representative signature 1',
        signatureDateField: 'date 1'
      }
    ]
  },
  {
    digitalFormId: 'US-CA/LIC 9158 - Telecommunications Device Notification',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'telecommunications-device-notification',
    signers: [
      [
        {
          role: 'resident',
          pdfSignatureField: 'Text2',
          signatureDateField: 'Date Sign'
        },
        {
          role: 'residentRep',
          pdfSignatureField: 'Text1',
          signatureDateField: 'Date Sign2'
        }
      ],
      {
        role: 'facilityRep',
        pdfSignatureField: 'Text3',
        signatureDateField: 'Date Sign3'
      }
    ]
  },
  {
    digitalFormId: 'US-CA/LIC 9172 - Functional Capability Assessment',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'functional-capability-assessment',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField:
          'SIGNATURE OF APPLICANT OR AUTHORIZED REPRESENTATIVE:',
        signatureDateField:
          'DATE COMPLETED BY APPLICANT OR AUTHORIZED REPRESENTATIVE:'
      },
      {
        role: 'facilityRep',
        pdfSignatureField: 'SIGNATURE OF LICENSEE OR FACILITY REPRESENTATIVE:',
        signatureDateField:
          'DATE COMPLETED BY LICENSEE OR FACILITY REPRESENTATIVE:'
      }
    ]
  }
] as const;

export type FormConfigUses = (typeof formConfigs)[number]['useFor'];
export type DigitalFormId = (typeof formConfigs)[number]['digitalFormId'];

let formConfigsByDigitalFormId: Record<DigitalFormId, IFormConfig> | undefined;
let formConfigsByUse: Record<string, IFormConfig> | undefined;

export function getFormConfig(
  digitalFormId?: string | null | undefined
): IFormConfig | undefined {
  if (!digitalFormId) {
    return;
  }

  // We create this caching layer so that our tests can override the formConfigs array
  // and still have the dictionaries work, but in a standard runtime environment
  // we don't need to contanstantly recompute the dictionaries.
  if (!formConfigsByDigitalFormId) {
    formConfigsByDigitalFormId = keyBy(formConfigs, 'digitalFormId');
  }

  return formConfigsByDigitalFormId[digitalFormId];
}

export function getFormConfigByUse(
  stateId: StateId,
  useFor: FormConfigUses
): IFormConfig | undefined {
  // See above comment about why we cache the dictionaries
  if (!formConfigsByUse) {
    formConfigsByUse = keyBy(
      formConfigs,
      (item) => `${item.state}/${item.useFor}`
    );
  }

  return formConfigsByUse[`${stateId}/${useFor}`];
}

export function documentHasForm(digitalFormId: string): boolean {
  return digitalFormId ? !!getFormConfig(digitalFormId) : false;
}

// This is for testing purposes only, so that we can reset the dictionaries
// between tests.
export function resetConfigDictionarys(): void {
  formConfigsByDigitalFormId = undefined;
  formConfigsByUse = undefined;
}
