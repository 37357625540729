alc-callout {
  display: block;
}
alc-callout .default {
  @apply border-gray-400 bg-gray-50 text-gray-700;
}
alc-callout .info {
  @apply border-sky-400 bg-sky-50 text-sky-700;
}
alc-callout .warning {
  @apply border-yellow-400 bg-yellow-50 text-yellow-700;
}
alc-callout .error {
  @apply border-red-400 bg-red-50 text-red-700;
}
alc-callout .success {
  @apply border-green-400 bg-green-50 text-green-700;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL3dvcmtzcGFjZS9mcm9udGVuZC9zcmMvYXBwL3NoYXJlZC9jb21wb25lbnRzL2NhbGxvdXQvY2FsbG91dC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOztBQUVBO0VBQ0U7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbImFsYy1jYWxsb3V0IHtcbiAgZGlzcGxheTogYmxvY2s7XG5cbiAgLmRlZmF1bHQge1xuICAgIEBhcHBseSBib3JkZXItZ3JheS00MDAgYmctZ3JheS01MCB0ZXh0LWdyYXktNzAwO1xuICB9XG5cbiAgLmluZm8ge1xuICAgIEBhcHBseSBib3JkZXItc2t5LTQwMCBiZy1za3ktNTAgdGV4dC1za3ktNzAwO1xuICB9XG5cbiAgLndhcm5pbmcge1xuICAgIEBhcHBseSBib3JkZXIteWVsbG93LTQwMCBiZy15ZWxsb3ctNTAgdGV4dC15ZWxsb3ctNzAwO1xuICB9XG5cbiAgLmVycm9yIHtcbiAgICBAYXBwbHkgYm9yZGVyLXJlZC00MDAgYmctcmVkLTUwIHRleHQtcmVkLTcwMDtcbiAgfVxuXG4gIC5zdWNjZXNzIHtcbiAgICBAYXBwbHkgYm9yZGVyLWdyZWVuLTQwMCBiZy1ncmVlbi01MCB0ZXh0LWdyZWVuLTcwMDtcbiAgfVxufVxuIl19 */