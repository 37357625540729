import { Params } from '$/models';
import { createActionGroup, props } from '@ngrx/store';

export const VitalLogPageActions = createActionGroup({
  source: 'Resident Vital Log Page',
  events: {
    'Load Vital Logs': (params: Params) => ({ params }),
    'Get Vital Logs': (params: Params) => ({ params }),
    'Delete Vital Log': props<{ id: string; params?: Params }>()
  }
});
