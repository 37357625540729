import { FeathersService } from '$/app/services/feathers.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RespirationLogsWsActions } from './respiration-logs.actions';
import { IRespirationLog } from '$shared/services/respiration-log.schema';
import { AbstractApiService } from '$/app/services';
@Injectable({ providedIn: 'root' })
export class RespirationLogsApiService extends AbstractApiService<IRespirationLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('respiration-logs', feathers, store, {
      entityName: 'respirationLog',
      created: RespirationLogsWsActions.respirationLogsCreated,
      patched: RespirationLogsWsActions.respirationLogsPatched,
      removed: RespirationLogsWsActions.respirationLogsRemoved
    });
  }
}
