import { Params, Update } from '$/models';
import { IMedicationOrder } from '$shared/medications/medication-order.schema';
import { createActionGroup, props } from '@ngrx/store';

export const MedicationOrdersListPageActions = createActionGroup({
  source: 'Medication Orders List Page',
  events: {
    'Load Medication Orders': (params: Params = { query: {} }) => ({ params }),
    'Get Medication Orders': (params: Params = { query: {} }) => ({ params }),
    'Delete Medication Order': props<{ id: string; params?: Params }>(),
    'Cancel Medication Order': props<Update<IMedicationOrder>>(),
    'Resend Medication Order': props<Update<IMedicationOrder>>()
  }
});
