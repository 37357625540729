import { AlcCommonModule } from '$/app/shared/alc-common.module';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input
} from '@angular/core';

@Component({
  selector: 'alc-release-highlight',
  imports: [AlcCommonModule],
  templateUrl: './release-highlight.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcReleaseHighlightComponent {
  type = input<'improvement' | 'feature' | 'bugfix' | 'change'>();
  title = input<string>();
  premium = input(false, { transform: booleanAttribute });

  tooltip = computed(() => {
    switch (this.type()) {
      case 'feature':
        return 'A feature is a brand new capability or functionality that was not previously available.';
      case 'improvement':
        return 'An improvement is a modification, or minor addition, to an "existing" feature that improves the user experience.';
      case 'change':
        return "A change is a modification that doesn't have a significant impact to user experience, but is still important to know about. Things like adding/removing items to lookup lists (i.e. items that appear in dropdowns), form validation rule changes, renaming/rephrasing, etc.";
      case 'bugfix':
        return 'A bugfix fixes a problem or issue that was causing a feature to not work as expected.';
    }
  });

  icon = computed(() => {
    switch (this.type()) {
      case 'improvement':
        return 'assets/icon/material/trending_up.svg';
      case 'feature':
        return 'assets/icon/material/new_releases_outline.svg';

      case 'bugfix':
        return 'assets/icon/material/bug_report.svg';
      case 'change':
        return 'assets/icon/material/change_circle.svg';
      default:
        throw new Error(
          `Invalid type for release highlight. Should be either "improvement", "change", "feature", or "bugfix". Got "${this.type}" instead`
        );
    }
  });
}
