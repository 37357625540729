import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { RespirationLogsPageActions } from '$/app/pages/notebook/shared/logs/respiration-log/respiration-log.actions';
import { EffectHelpersService } from '$/app/services';
import { RespirationLogFormPageActions } from '$/app/shared/pages/forms/log-forms/respiration-log-form/respiration-log-form.actions';
import { ApiData } from '$/app/utils';
import {
  getAllPages,
  reducePaginatedResponses
} from '$/app/utils/pagination/rxjs-operators';
import { IRespirationLog } from '$shared/services/respiration-log.schema';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RespirationLogsApiActions } from './respiration-logs.actions';
import { RespirationLogsApiService } from './respiration-logs.service';

@Injectable()
export class RespirationLogsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly respirationLogsService = inject(RespirationLogsApiService);

  getRespirationLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LogsPageActions.getRespirationLogs,
        RespirationLogsPageActions.getRespirationLogs
      ),
      this.effectHelpers.apiRequest({
        description: 'getting respiration logs',
        onRequest: (action) =>
          this.respirationLogsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.respirationLogsService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'respirationLogs',
            response,
            RespirationLogsApiActions.getRespirationLogsSuccess
          );
          return responseData.getActions();
        },
        onError: (error) =>
          RespirationLogsApiActions.getRespirationLogsFail({ error })
      })
    )
  );

  createRespirationLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RespirationLogFormPageActions.createRespirationLog),
      this.effectHelpers.apiRequest({
        description: 'Create respiration log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.respirationLogsService.create(action.respirationLog).pipe(
            tap((data: IRespirationLog) => {
              this.effectHelpers.dismissModal({ data });
            })
          );
        },
        onSuccess: (response) =>
          RespirationLogsApiActions.createRespirationLogSuccess({
            respirationLog: response
          }),
        onError: (error) =>
          RespirationLogsApiActions.createRespirationLogFail({ error })
      })
    );
  });

  updateRespirationLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RespirationLogFormPageActions.updateRespirationLog),
      switchMap((action) => {
        return this.respirationLogsService
          .patch(action.id, action.changes, action.params)
          .pipe(
            tap((data: IRespirationLog) => {
              this.effectHelpers.dismissModal({ data });
            }),
            map((respirationLog: IRespirationLog) =>
              RespirationLogsApiActions.updateRespirationLogSuccess({
                respirationLog
              })
            ),
            catchError((error) =>
              of(RespirationLogsApiActions.updateRespirationLogFail({ error }))
            )
          );
      })
    );
  });

  deleteRespirationLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RespirationLogsPageActions.deleteRespirationLog),
      this.effectHelpers.apiRequest({
        description: 'Delete respiration log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => this.respirationLogsService.delete(action.id),
        onSuccess: (response) =>
          RespirationLogsApiActions.deleteRespirationLogSuccess({
            id: response.id
          }),
        onError: (error) =>
          RespirationLogsApiActions.deleteRespirationLogFail({ error })
      })
    );
  });
}
