import { Params } from '$/models';
import { IFacilitySetting } from '$shared/facility-settings/types';
import { createActionGroup, props } from '@ngrx/store';

export const IpSettingsComponentActions = createActionGroup({
  source: 'IP Settings Component',
  events: {
    'Update Ip Setting': props<{
      id: string | null;
      changes: Partial<IFacilitySetting>;
      params?: Params;
    }>()
  }
});
