import { createReducer } from '@ngrx/store';
import { createFeature } from '@ngrx/store';
import {
  initialState,
  respirationLogsAdapter,
  RespirationLogPageQueries
} from './respiration-logs.state';
import { on } from '@ngrx/store';
import {
  RespirationLogsApiActions,
  RespirationLogsGeneralActions,
  RespirationLogsWsActions
} from './respiration-logs.actions';
import { getPaginationData, getRecords } from '$/app/utils';

export const respirationLogsFeature = createFeature({
  name: 'respirationLogs',
  reducer: createReducer(
    initialState,
    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),
    on(
      RespirationLogsApiActions.loadRespirationLogsSuccess,
      (state, action) => {
        const queryType = action.metadata?.pageQueryType;
        const pagination = getPaginationData<RespirationLogPageQueries>(
          state,
          action.respirationLogs,
          queryType
        );
        const data = getRecords(action.respirationLogs);

        return respirationLogsAdapter.setAll(data, {
          ...state,
          pagination,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),
    on(RespirationLogsApiActions.getRespirationLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<RespirationLogPageQueries>(
        state,
        action.respirationLogs,
        queryType
      );
      const data = getRecords(action.respirationLogs);

      return respirationLogsAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),
    on(
      RespirationLogsApiActions.createRespirationLogSuccess,
      (state, action) => {
        return respirationLogsAdapter.addOne(action.respirationLog, {
          ...state,
          loading: false,
          error: null
        });
      }
    ),
    on(
      RespirationLogsApiActions.updateRespirationLogSuccess,
      (state, action) => {
        return respirationLogsAdapter.updateOne(
          { id: action.respirationLog.id, changes: action.respirationLog },
          {
            ...state,
            loading: false,
            error: null
          }
        );
      }
    ),
    on(RespirationLogsApiActions.deleteRespirationLogSuccess, (state, action) =>
      respirationLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      })
    ),
    on(
      RespirationLogsApiActions.loadRespirationLogsFail,
      RespirationLogsApiActions.getRespirationLogsFail,
      RespirationLogsApiActions.createRespirationLogFail,
      RespirationLogsApiActions.updateRespirationLogFail,
      RespirationLogsApiActions.deleteRespirationLogFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),
    on(
      RespirationLogsWsActions.respirationLogsCreated,
      RespirationLogsWsActions.respirationLogsPatched,
      (state, action) => {
        return respirationLogsAdapter.upsertOne(action.respirationLog, state);
      }
    ),
    on(RespirationLogsWsActions.respirationLogsRemoved, (state, action) => {
      return respirationLogsAdapter.removeOne(action.id, state);
    }),

    on(RespirationLogsGeneralActions.addRespirationLogs, (state, action) => {
      return respirationLogsAdapter.upsertMany(action.respirationLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),
    on(RespirationLogsGeneralActions.clearRespirationLogs, () => {
      return respirationLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
