import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

type Appearance = 'fill' | 'underline' | 'plain';

@Component({
  selector: 'alc-form-divider',
  imports: [CommonModule],
  templateUrl: './form-divider.component.html',
  styleUrls: ['./form-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex items-center p-4 w-full text-dark font-medium',
    '[class.appearance-underline]': 'appearance() === "underline"',
    '[class.appearance-fill]': 'appearance() === "fill"',
    '[class.appearance-plain]': 'appearance() === "plain"'
  }
})
export class AlcFormDividerComponent {
  appearance = input<Appearance>('fill');
}
