import { createReducer } from '@ngrx/store';
import { createFeature } from '@ngrx/store';
import {
  initialState,
  vitalLogsAdapter,
  VitalLogPageQueries
} from './vital-logs.state';
import { on } from '@ngrx/store';
import {
  VitalLogsApiActions,
  VitalLogsGeneralActions,
  VitalLogsWsActions
} from './vital-logs.actions';
import { getPaginationData, getRecords } from '$/app/utils';

export const vitalLogsFeature = createFeature({
  name: 'vitalLogs',
  reducer: createReducer(
    initialState,

    on((state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }),
    on(VitalLogsApiActions.loadVitalLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<VitalLogPageQueries>(
        state,
        action.vitalLogs,
        queryType
      );
      const data = getRecords(action.vitalLogs);

      return vitalLogsAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),
    on(VitalLogsApiActions.getVitalLogsSuccess, (state, action) => {
      const queryType = action.metadata?.pageQueryType;
      const pagination = getPaginationData<VitalLogPageQueries>(
        state,
        action.vitalLogs,
        queryType
      );
      const data = getRecords(action.vitalLogs);

      return vitalLogsAdapter.setAll(data, {
        ...state,
        pagination,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(VitalLogsApiActions.createVitalLogSuccess, (state, action) => {
      return vitalLogsAdapter.addOne(action.vitalLog, {
        ...state,
        loading: false,
        error: null
      });
    }),
    on(VitalLogsApiActions.updateVitalLogSuccess, (state, action) => {
      return vitalLogsAdapter.updateOne(
        { id: action.vitalLog.id, changes: action.vitalLog },
        {
          ...state,
          loading: false,
          error: null
        }
      );
    }),
    on(VitalLogsApiActions.deleteVitalLogSuccess, (state, action) => {
      return vitalLogsAdapter.removeOne(action.id, {
        ...state,
        loading: false,
        error: null
      });
    }),
    on(
      VitalLogsApiActions.loadVitalLogsFail,
      VitalLogsApiActions.getVitalLogsFail,
      VitalLogsApiActions.createVitalLogFail,
      VitalLogsApiActions.updateVitalLogFail,
      VitalLogsApiActions.deleteVitalLogFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),
    on(
      VitalLogsWsActions.vitalLogsCreated,
      VitalLogsApiActions.createVitalLogSuccess,
      (state, action) => {
        return vitalLogsAdapter.upsertOne(action.vitalLog, state);
      }
    ),

    on(VitalLogsWsActions.vitalLogsRemoved, (state, action) => {
      return vitalLogsAdapter.removeOne(action.id, state);
    }),

    on(VitalLogsGeneralActions.addVitalLogs, (state, action) => {
      return vitalLogsAdapter.upsertMany(action.vitalLogs, {
        ...state,
        loading: false,
        error: null
      });
    }),

    on(VitalLogsGeneralActions.clearVitalLogs, () => {
      return vitalLogsAdapter.removeAll({
        ...initialState
      });
    })
  )
});
