import { inject, Injectable } from '@angular/core';
import { EffectHelpersService } from '$/app/services';
import { Actions } from '@ngrx/effects';
import { VitalLogsApiService } from './vital-logs.service';
import { createEffect, ofType } from '@ngrx/effects';
import { ApiData } from '$/app/utils';
import {
  getAllPages,
  reducePaginatedResponses
} from '$/app/utils/pagination/rxjs-operators';
import { VitalLogsApiActions } from './vital-logs.actions';
import { VitalLogFormPageActions } from '$/app/shared/pages/forms/log-forms/vital-logs-form/vital-log-form.actions';
import { VitalLogPageActions } from '$/app/pages/notebook/shared/logs/vital-logs/vital-log.actions';
import { LogsPageActions } from '$/app/pages/notebook/residents/logs-overview/logs-overview.actions';
import { tap } from 'rxjs/operators';
import { IVitalLog } from '$shared/services/vital-log.schema';

@Injectable()
export class VitalLogEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly vitalLogsService = inject(VitalLogsApiService);

  getVitalLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VitalLogPageActions.getVitalLogs, LogsPageActions.getVitalLogs),
      this.effectHelpers.apiRequest({
        description: 'getting vital logs',
        onRequest: (action) =>
          this.vitalLogsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.vitalLogsService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'vitalLogs',
            response,
            VitalLogsApiActions.getVitalLogsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => VitalLogsApiActions.getVitalLogsFail({ error })
      })
    )
  );

  createVitalLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VitalLogFormPageActions.createVitalLog),
      this.effectHelpers.apiRequest({
        description: 'Create vital log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.vitalLogsService.create(action.vitalLog).pipe(
            tap((data: IVitalLog) => {
              this.effectHelpers.dismissModal({ data });
            })
          ),
        onSuccess: (response) =>
          VitalLogsApiActions.createVitalLogSuccess({ vitalLog: response }),
        onError: (error) => VitalLogsApiActions.createVitalLogFail({ error })
      })
    );
  });

  updateVitalLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VitalLogFormPageActions.updateVitalLog),
      this.effectHelpers.apiRequest({
        description: 'Update vital log',
        useMapOperator: 'exhaustMap',
        onRequest: (action: {
          id: string;
          changes: Partial<IVitalLog>;
          params?: any;
        }) =>
          this.vitalLogsService
            .patch(action.id, action.changes, action.params)
            .pipe(
              tap((data: IVitalLog) => {
                this.effectHelpers.dismissModal({ data });
              })
            ),
        onSuccess: (response) =>
          VitalLogsApiActions.updateVitalLogSuccess({ vitalLog: response }),
        onError: (error) => VitalLogsApiActions.updateVitalLogFail({ error })
      })
    );
  });

  deleteVitalLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VitalLogPageActions.deleteVitalLog),
      this.effectHelpers.apiRequest({
        description: 'Delete vital log',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => this.vitalLogsService.delete(action.id),
        onSuccess: (response) =>
          VitalLogsApiActions.deleteVitalLogSuccess({ id: response.id }),
        onError: (error) => VitalLogsApiActions.deleteVitalLogFail({ error })
      })
    );
  });
}
