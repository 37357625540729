import { FeathersService } from '$/app/services/feathers.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractApiService } from '$/app/services';
import { IVitalLog } from '$shared/services/vital-log.schema';
import { VitalLogsWsActions } from './vital-logs.actions';
@Injectable({ providedIn: 'root' })
export class VitalLogsApiService extends AbstractApiService<IVitalLog> {
  constructor(feathers: FeathersService, store: Store) {
    super('vital-logs', feathers, store, {
      entityName: 'vitalLog',
      created: VitalLogsWsActions.vitalLogsCreated,
      patched: VitalLogsWsActions.vitalLogsPatched,
      removed: VitalLogsWsActions.vitalLogsRemoved
    });
  }
}
