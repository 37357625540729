import { FacilitiesSelectors, UserSelectors } from '$/app/store';
import { FeatureFlagId } from '$shared/lookups/feature-flags.lookup';
import { PermissionId } from '$shared/permissions';
import { MaybeArray } from '$shared/types/utility-types';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { castArray, every, some } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  private readonly store = inject(Store);

  private readonly permissions = this.store.selectSignal(
    UserSelectors.selectPermissions
  );

  private readonly featureFlags = this.store.selectSignal(
    FacilitiesSelectors.selectFeatureFlags
  );

  public hasPermission(permissionId: MaybeArray<PermissionId>): boolean {
    return checkPermission(this.permissions(), permissionId);
  }

  public hasFeatureFlags(...flags: FeatureFlagId[]): boolean {
    return every(flags, (flag) => this.featureFlags()[flag]);
  }

  public hasSomeFeatureFlags(...flags: FeatureFlagId[]): boolean {
    return some(flags, (flag) => this.featureFlags()[flag]);
  }
}

export function checkPermission(
  permissions: Partial<Record<PermissionId, boolean>> | undefined,
  permissionId: MaybeArray<PermissionId>
) {
  return castArray(permissionId).some(
    (permissionId) => permissions?.[permissionId]
  );
}
